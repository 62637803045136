import {Container} from 'react-bootstrap'
import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';

const Section5 = () => {
    const handleDragStart = (e: any) => e.preventDefault();

    const userOpenions = [
        {
            message: "We use 1CDN as our media management provider for our applications. Very easily, we use the APIs provided to send our files. This one sends us back a link that will be used in the application. In no time at all, there is no need to manage files, store them and worry about their availability.",
            user: "Tommy V.",
            position: "Senior developer",
            location: "USA"
        },
        {
            message: "When you are serving content around the world, it is a great concern that users from all over the globe have access to your resources as quickly as possible. Using 1CDN services help us solve this constraint very simply.",
            user: "Adrian S.",
            position: "Web engineer",
            location: "Germany"
        },
        {
            message: "It's easy and friendly, I upload my files and just send the links to my clients. No more problems with storage or fear of losing my files.",
            user: "Emilie B.",
            position: "Photographer",
            location: "France"
        },
    ]

    const items = userOpenions.map((item: any, index: number) => (
        <div key={index} className="border border-dark p-2 people mx-2" onDragStart={handleDragStart}
             role="presentation">
            <h4 className='fs-5'>
                {item.message}
            </h4>
            <p className='mb-1'>{item.user}</p>
            <small>
                {item.position}
            </small>
            <br />
            <small>
                {item.location}
            </small>
        </div>
    ))

    return (
        <section className='section5'>
            <img loading="lazy" src="/images/progress.svg" alt="11CDN" className='img-fluid' width={1582} height={317}
                 title='11CDN - Statistics'/>
            <div className="cs-p">
                <Container fluid={'lg'}>
                    <div className="d-flex align-items-center justify-content-center">
                        <ul className="list-unstyled mb-0 p-0 d-flex flex-sm-row flex-column">
                            <li>
                                <p className='fs-1'>+30M <small>users</small></p>
                            </li>
                            <li className='mx-sm-5 mx-0 my-sm-0 my-5'>
                                <p className='fs-1'>23 <small>servers</small></p>
                            </li>
                            <li>
                                <p className='fs-1'>12 <small>countries</small></p>
                            </li>
                        </ul>
                    </div>
                </Container>
            </div>
            <h4 className='coloredTitle text-center fs-1'>
        <span>
          Our target:
        </span>
                &nbsp;100%&nbsp;
                <span>satisfaction</span>
            </h4>
            <Container>
                <AliceCarousel
                    responsive={{
                        0: {
                            items: 1,
                        },
                        768: {
                            items: 2
                        },
                        1024: {
                            items: 3,
                        }
                    }}
                    mouseTracking
                    //autoHeight={true}
                    // autoWidth={true}
                    controlsStrategy='responsive'
                    disableButtonsControls={true}
                    disableDotsControls={false}
                    items={items}/>
            </Container>
        </section>
    )
}

export default Section5