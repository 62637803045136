import React from 'react'
import { Container } from 'react-bootstrap'

const Section4 = () => {
  return (
    <section className='section4 cs-p' id='about'>
      <Container fluid="lg">
        <p className="text-end">
          About
        </p>
        <h3 className='fs-1'>
          <img loading="lazy" src="/images/lines.svg" alt="1CDN" className='img-fluid me-3' width={60} height={70} title='1CDN - about us' />
          About <span>Us</span>
        </h3>
        <p className='mb-0'>
          As media sharing, file quality and size have increased significantly on the web, it is essential to rely on a CDN to ensure optimal response times for your files and content.
        </p>
      </Container>
    </section>
  )
}

export default Section4