import React from 'react'
import { Navigate } from 'react-router-dom'
import useAuth from '../../hooks/useAuth'

const ProtectedRoute = ({ permission, children }: { permission: string, children: React.ReactElement }) => {
    const { user } = useAuth()

    if(!user || !user.user_permissions[permission])
        return <Navigate to={'/control-panel'} />

    return children
}

export default ProtectedRoute