import { AxiosError } from 'axios'
import { Button, Spinner } from 'react-bootstrap'
import { useQuery } from 'react-query'
import { Link } from 'react-router-dom'
import { toast } from 'react-toastify'
import useAuth from '../../../hooks/useAuth'
import HttpService from '../../../services/http'

const TotalUploadedFiles = () => {
  const { customer_identification } = useAuth();

  const { isLoading, isError, isSuccess, data: res, refetch } = useQuery("total-files", () => HttpService.get(`/${customer_identification}/cdn/count-files`, {
    auth: HttpService.getToken()
  }), {
    onError: (err: AxiosError<any, any>) => {
      toast.error(err.response?.data?.message || err.message)
    }
  })

  let content;

  if (isLoading)
    content = <Spinner size='sm' animation='grow' />

  if (isError)
    content = <Button onClick={() => refetch()} size='sm'>Reload</Button>

  if (isSuccess)
    content = <>
      {res?.data.element > 0 ?
        (
          <>
            <h4 className='mb-0 fw-bold'>
              Total uploaded files
            </h4>
            <img loading="lazy" src="https://cdn.pinkswan.ch/application/CDN/cdn_upload_file_1.svg" alt="total files" className='img-fluid my-2' width={190} />
            <div className='bg-dl-blue px-5 py-2 rounded text-white w-75 mx-auto'>
              <h5 className='mb-0'><span>{res?.data.element} Files</span></h5>
            </div>
          </>
        )
        :
        (
          <>
            <h4 className='fw-bold mb-4'>
              Start upload your content now
            </h4>
            <img loading="lazy" src="https://cdn.pinkswan.ch/application/CDN/cdn_teleporting.svg" alt="1CDN" className="img-fluid" width="250" height="150" title="1CDN - Support any format"></img>
            <div className='mt-3'>
              <Link to={'upload-center'} className='btn btn-success rounded-pill'>Upload new files</Link>
            </div>
          </>
        )
      }
    </>

  return (
    <div className='dl-shadow-box text-center'>
      {content}
    </div >
  )
}

export default TotalUploadedFiles