import { useLayoutEffect } from 'react';
import { useLocation } from 'react-router-dom';

const ScrollToTop = ({ children }: { children: JSX.Element }) => {
  const location = useLocation();
  useLayoutEffect(() => {
    let mainDash = document.getElementById("content-area");

    if (mainDash)
      // mainDash.scrollTo({
      //   'behavior': 'smooth',
      //   'left': 0,
      //   'top': 0
      // });
      mainDash.scrollTop = 0

    // document.documentElement.scrollTo(0, 0);
    document.body.scrollIntoView()
    document.documentElement.scrollTop = 0

  }, [location.pathname]);
  return children;
};

export default ScrollToTop;
