/* eslint-disable jsx-a11y/anchor-is-valid */
import { useState } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { cdnPlans } from "../../../routes/Plans";

export const PlansInModal = ({
  user_identification,
  isInBilling = false,
  hasPremiumPlan = false,
}: {
  user_identification: string;
  isInBilling: boolean;
  hasPremiumPlan?: boolean;
}) => {
  const [IsYearly, setIsYearly] = useState(true);

  const renderPlanSlides = () => {
    let plans = [];

    if (IsYearly)
      plans = cdnPlans.filter((plan: any) => plan.recurrence === "ANNUALLY");
    else plans = cdnPlans.filter((plan: any) => plan.recurrence === "MONTHLY");

    if (hasPremiumPlan) plans = plans.filter((p) => p.plan_title !== "Free");

    return plans.map((plan, index) => (
      <div className="planCard my-3" key={index}>
        <Row>
          <Col md={4} className="mb-md-0 mb-3 cl1">
            <h3>{plan.plan_title}</h3>
            <h1>
              {plan.amount / 100 > 0 ? (
                <>
                  {plan.recurrence === "ANNUALLY" ? (
                    <>
                      {(plan.amount / 100 / 12).toLocaleString("en-US", {
                        style: "currency",
                        currency: plan.currency,
                        maximumFractionDigits: 0,
                        minimumFractionDigits: 0,
                      })}
                      <small> / Month </small>
                      <sup>
                        <span
                          style={{
                            color: "#808080",
                            textDecoration: "line-through",
                          }}
                        >
                          <span style={{ color: "#808080" }}>
                            {cdnPlans.find(
                              (item) =>
                                item.recurrence === "MONTHLY" &&
                                item.plan_title === "Premium"
                            )?.amount &&
                              "$" +
                                (cdnPlans.find(
                                  (item) =>
                                    item.recurrence === "MONTHLY" &&
                                    item.plan_title === "Premium"
                                )?.amount as number) /
                                  100}
                          </span>
                        </span>
                      </sup>
                    </>
                  ) : (
                    <>
                      {(plan.amount / 100).toLocaleString("en-US", {
                        style: "currency",
                        currency: plan.currency,
                        maximumFractionDigits: 0,
                        minimumFractionDigits: 0,
                      })}
                      <small> / Month </small>
                    </>
                  )}
                </>
              ) : null}

              {/* {(plan.amount / 100).toLocaleString('en-US', {
                style: 'currency',
                currency: plan.currency,
                maximumFractionDigits: 0
              }) + " " + plan.currency} */}
            </h1>
            {plan.recurrence === "ANNUALLY" && plan.plan_title !== "Free" && (
              <a className="btn btn-dark rounded-pill mt-3">
                Annual one-time charge of <b>${plan.amount / 100}</b>
              </a>
            )}
            {plan.recurrence === "MONTHLY" && plan.plan_title !== "Free" && (
              <a className="btn btn-dark rounded-pill mt-3">
                Pay annually and save up to <b>20%</b>
              </a>
            )}
          </Col>
          <Col md={5} className="mb-md-0 mb-3">
            <ul className="list-unstyled mb-0 p-0 h-100 d-flex flex-column featuresList">
              {plan.features.map((fe: string, index: number) => (
                <li key={index}>
                  <svg
                    width="24"
                    height="17"
                    viewBox="0 0 24 17"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M22.3186 0.609052L8.49963 14.2145C8.40672 14.3063 8.29631 14.3792 8.17473 14.4289C8.05314 14.4786 7.92279 14.5042 7.79113 14.5042C7.65948 14.5042 7.52912 14.4786 7.40754 14.4289C7.28596 14.3792 7.17554 14.3063 7.08263 14.2145L1.73863 8.94776C1.64572 8.85592 1.53531 8.78304 1.41373 8.73332C1.29214 8.68359 1.16179 8.658 1.03013 8.658C0.898479 8.658 0.768121 8.68359 0.646539 8.73332C0.524956 8.78304 0.414542 8.85592 0.321633 8.94776V8.94776C0.228356 9.03924 0.154344 9.14795 0.103842 9.26767C0.0533404 9.38738 0.0273437 9.51573 0.0273438 9.64536C0.0273437 9.77499 0.0533404 9.90334 0.103842 10.0231C0.154344 10.1428 0.228356 10.2515 0.321633 10.343L5.66763 15.6057C6.23158 16.16 6.99583 16.4713 7.79263 16.4713C8.58944 16.4713 9.35369 16.16 9.91763 15.6057L23.7356 2.00327C23.8288 1.91181 23.9026 1.80315 23.9531 1.68353C24.0035 1.56391 24.0294 1.43567 24.0294 1.30616C24.0294 1.17665 24.0035 1.04841 23.9531 0.928789C23.9026 0.809167 23.8288 0.700514 23.7356 0.609052C23.6427 0.517211 23.5323 0.444336 23.4107 0.394612C23.2891 0.344887 23.1588 0.31929 23.0271 0.31929C22.8955 0.31929 22.7651 0.344887 22.6435 0.394612C22.522 0.444336 22.4115 0.517211 22.3186 0.609052Z"
                      fill="#0C271B"
                    />
                  </svg>
                  {fe}
                </li>
              ))}
            </ul>
          </Col>
          <Col md={3} className="text-center">
            <div className="h-100 d-flex flex-column align-items-md-end align-items-start">
              {plan.plan_title === "Premium" && (
                <div className="cs-plan-badge mb-md-0 mb-3">
                  <svg
                    width="14"
                    height="14"
                    viewBox="0 0 14 14"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g clipPath="url(#clip0_511_3817)">
                      <path
                        d="M13.5455 3.35008L11.7891 0.977084C11.5731 0.675112 11.2883 0.428965 10.9582 0.259045C10.6281 0.0891242 10.2622 0.000323004 9.89094 1.02806e-06H4.11011C3.74065 -0.000345762 3.37638 0.0870478 3.04729 0.25499C2.7182 0.422931 2.4337 0.66662 2.21719 0.966001L0.429276 3.35592C0.138172 3.7664 -0.0121642 4.26013 0.000769877 4.76319C0.013704 5.26625 0.189212 5.7516 0.501026 6.14658L5.62969 13.3379C5.79313 13.5449 6.00145 13.712 6.23892 13.8267C6.47639 13.9414 6.73681 14.0007 7.00053 14C7.26877 13.9995 7.53331 13.9374 7.7738 13.8186C8.01428 13.6997 8.2243 13.5273 8.38769 13.3146L13.4755 6.20317C13.8013 5.80241 13.9849 5.30489 13.9976 4.78858C14.0103 4.27226 13.8513 3.76634 13.5455 3.35008V3.35008ZM10.8459 1.66308L12.6064 4.04192C12.6157 4.05475 12.618 4.06992 12.6274 4.08333H9.77369L8.94069 1.16667H9.89094C10.0782 1.16725 10.2625 1.21261 10.4287 1.29897C10.5948 1.38533 10.7378 1.51017 10.8459 1.66308V1.66308ZM7.00053 11.1522L5.42553 5.25H8.57553L7.00053 11.1522ZM5.44069 4.08333L6.27369 1.16667H7.72736L8.56036 4.08333H5.44069ZM3.16044 1.65492C3.26868 1.50406 3.41125 1.38111 3.57637 1.29622C3.74149 1.21132 3.92444 1.16692 4.11011 1.16667H5.06036L4.22736 4.08333H1.35269C1.36086 4.0705 1.36319 4.05475 1.37253 4.0425L3.16044 1.65492ZM1.42853 5.43958C1.38641 5.38009 1.35026 5.31659 1.32061 5.25H4.21919L5.96569 11.8008L1.42853 5.43958ZM8.03361 11.8067L9.78361 5.25H12.6921C12.6545 5.33707 12.607 5.41949 12.5504 5.49558L8.03361 11.8067Z"
                        fill="white"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_511_3817">
                        <rect width="14" height="14" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                  <span className="ms-2">Includes Free features</span>
                </div>
              )}
              {isInBilling ? (
                <a
                  href={`${
                    process.env.REACT_APP_PAYMENT_SERVICE
                  }/payment/subscription?template_image_url=${
                    process.env.REACT_APP_PAYMENT_SERVICE_TEMPLATE_IMAGE_URL
                  }&redirect_page=${
                    process.env.REACT_APP_PAYMENT_SERVICE_REDIRECT_PAGE
                  }/${
                    isInBilling ? "control-panel/billing" : "login"
                  }&user_identification=${user_identification}&payment_plan_identification=${
                    plan.plan_code
                  }&language_code=en&entity_identification=${
                    process.env.REACT_APP_CDN_IDENTIFICATION
                  }`}
                  className="btn btn-dark w-100 mt-auto"
                >
                  Subscribe
                </a>
              ) : (
                <Link
                  to={`/register?plan=${plan.plan_code}`}
                  className="btn btn-dark"
                >
                  Get started
                </Link>
              )}
            </div>
          </Col>
        </Row>
      </div>
    ));
  };

  return (
    <div className="plans-page">
      <Container fluid="lg">
        <div className="title">
          <Row className="mb-3 align-items-end">
            <Col md={6} className="mb-sm-0 mb-3">
              <h1 className="mb-0">
                Our <br /> Plans
              </h1>
            </Col>
            <Col md={6}>
              <p className="mb-0">
                With 1CDN's various plans you will be able to choose the one
                that is more capable of your needs, usage amount, and website
                users. We are here to build the solution to your requirements.
              </p>
            </Col>
          </Row>
          {/* <div className="d-flex mb-3">
            Monthly
            <Form.Check
              type="switch"
              id="plans-switch"
              className='ms-2'
              defaultChecked={IsYearly}
              label='Yearly'
              onChange={(e) => setIsYearly(e.target.checked ? true : false)}
            />
          </div> */}
          <div className="cs-switch mb-md-0 mb-3">
            <Button
              variant={!IsYearly ? "dark" : "link"}
              className="rounded-pill"
              onClick={() => setIsYearly(false)}
            >
              Monthly
            </Button>

            <Button
              variant={IsYearly ? "dark" : "link"}
              className="rounded-pill"
              onClick={() => setIsYearly(true)}
            >
              Annually
            </Button>
          </div>
        </div>
        {renderPlanSlides()}
      </Container>
    </div>
  );
};

export default PlansInModal;
