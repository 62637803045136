import { useState } from "react"
import Contexts from "../contexts"

function UiProvider({ children }: { children: React.ReactNode }) {
    const [uiState, setUiState] = useState<{ [key: string]: any }>({})

    const setUiItem = (key: string, value: any) => {
        setUiState({ ...uiState, [key]: value })
    }

    const getUiItem = (key: string) => {
        if (uiState)
            return uiState[key]
        else
            return null
    }

    const clear = () => {
        setUiState({})
    }

    let value = { uiState, setUiItem, getUiItem, clear }

    return <Contexts.UiContext.Provider value={value}>{children}</Contexts.UiContext.Provider>;
}

export default UiProvider