import { Helmet } from "react-helmet-async"
import IntroPopUp from "../components/pages/home/IntroPopUp"
import Section1 from "../components/pages/home/Section1"
import Section2 from "../components/pages/home/Section2"
import Section3 from "../components/pages/home/Section3"
import Section4 from "../components/pages/home/Section4"
import Section5 from "../components/pages/home/Section5"
import Section6 from "../components/pages/home/Section6"
import Section7 from "../components/pages/home/Section7"

const Home = () => {
  return (
    <>
      <Helmet>
        <title>1CDN - Home page</title>
        <link rel="canonical" href={`${process.env.REACT_APP_PAYMENT_SERVICE_REDIRECT_PAGE}`} />
      </Helmet>
      <Section1 />
      <Section2 />
      <Section3 />
      <Section4 />
      <Section5 />
      <Section6 />
      <Section7 />
      <IntroPopUp />
    </>
  )
}

export default Home