const authProvider = {
  isAuthenticated: false,
  login(callback: VoidFunction) {
    authProvider.isAuthenticated = true;
    callback()
  },
  logout(callback: VoidFunction) {
    authProvider.isAuthenticated = false;
    callback()
  },
};

export { authProvider };
