import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from "react-router-dom";
import App from './App';
import reportWebVitals from './reportWebVitals';
import AuthProvider from './providers/AuthProvider';
import { HelmetProvider } from 'react-helmet-async';
import ErrorBoundary from './components/public/ErrorBoundary';
import LocalStorageProvider from './providers/LocalStorageProvider';
import { QueryClient, QueryClientProvider } from 'react-query';
import UiProvider from './providers/UiProvider';

import './index.css';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: false,
    }
  }
})

root.render(
  <React.StrictMode>
    <ErrorBoundary>
      <QueryClientProvider client={queryClient}>
        <LocalStorageProvider>
          <BrowserRouter>
            <HelmetProvider>
              <AuthProvider>
                <UiProvider>
                  <App />
                </UiProvider>
              </AuthProvider>
            </HelmetProvider>
          </BrowserRouter>
        </LocalStorageProvider>
      </QueryClientProvider>
    </ErrorBoundary>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
