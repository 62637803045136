import React, { useState } from 'react'
import { Button, ButtonGroup, Dropdown, Modal, Spinner, Table } from 'react-bootstrap'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import {
    ColumnDef,
    flexRender,
    getCoreRowModel,
    useReactTable,
} from '@tanstack/react-table'
import useAuth from '../../../hooks/useAuth'
import HttpService from '../../../services/http'
import { toast } from 'react-toastify'
import PlansInModal from './PlansInModal'
import Swal from 'sweetalert2'
import { AxiosError } from 'axios'
import { Helmet } from 'react-helmet-async'
import { useLocation } from 'react-router-dom'

type Card = {
    paymentMethodId: string;
    brand: string;
    country: string;
    expMonth: string;
    expYear: string;
    last4: string;
}

const Billing = () => {
    const { customer_identification } = useAuth()

    const location: any = useLocation();

    const [selectedPlan, setSelectedPlan] = useState('My plans');
    const [selectedPlanLabel, setSelectedPlanLabel] = useState('My plans');
    const [selectedPlanStatus, setSelectedPlanStatus] = useState<undefined | 'ACTIVE' | 'CANCELED' | 'ENDED'>(undefined);
    const [selectedActivePlanDefaultCardId, setSelectedActivePlanDefaultCardId] = useState();
    const [planCards, setPlanCards] = useState<Card[] | []>([])
    const [showPlanHistoryModal, setShowPlanHistoryModal] = useState(false)
    const [planHistoryData, setPlanHistoryData] = useState<any[] | []>([])
    const [showPlansModal, setshowPlansModal] = useState(location?.state?.showPlansModal || false)

    const queryClient = useQueryClient();

    const {
        isLoading,
        isError,
        data: res,
        refetch
    } = useQuery('owner-plans', () => HttpService.get(`/${customer_identification}/billing/plan-status`), {
        onError: (err: AxiosError<any, any>) => {
            toast.error(err.response?.data?.message || err.message)
        }
    })

    const CancelPlanSubscriptionMutation = useMutation<null, AxiosError<any, any>, any>(() => HttpService.post(`/${customer_identification}/billing/${selectedPlan}/cancel-subscription`), {
        onSuccess: (data: any) => {
            toast.success("Plan subscription canceled");

            queryClient.invalidateQueries('owner-plans');
            queryClient.invalidateQueries(['plan-cards', selectedPlan])
            queryClient.invalidateQueries(['plan-detailed-info', selectedPlan])

            setPlanCards([]);
            setSelectedPlan('My plans');
            setSelectedPlanLabel('My plans');
            setSelectedPlanStatus(undefined);
        },
        onError: (err) => {
            toast.error(err.response?.data.message || err.message)
        }
    })

    const UpdateSubscriptionCardMutation = useMutation<string, AxiosError<any, any>, any>((payment_method_id) => HttpService.put(`/${customer_identification}/billing/${selectedPlan}/${payment_method_id}/update-subscription-card`, null), {
        onSuccess: (res: any) => {
            toast.success("Success!");

            queryClient.invalidateQueries(['plan-cards', selectedPlan])
            queryClient.invalidateQueries(['plan-detailed-info', selectedPlan])

            handleFetchPlanCards(selectedPlan, selectedPlanStatus, selectedPlanLabel);
        },
        onError: (err) => {
            toast.error(err.response?.data?.message || err.message)
        }
    })

    const RemovePaymentMethodMutation = useMutation<string, AxiosError<any, any>, any>((payment_method_id) => HttpService.delete(`/${customer_identification}/billing/${selectedPlan}/${payment_method_id}/remove-payment-method`), {
        onSuccess: (res: any) => {
            toast.success("Card removed successfully");

            queryClient.invalidateQueries(['plan-cards', selectedPlan])
            queryClient.invalidateQueries(['plan-detailed-info', selectedPlan])

            handleFetchPlanCards(selectedPlan, selectedPlanStatus, selectedPlanLabel);
        },
        onError: (err) => {
            toast.error(err.response?.data?.message || err.message)
        }
    })

    const handleFetchPlanCards = (plan: string, status: 'ACTIVE' | 'CANCELED' | 'ENDED' | undefined, description: string) => {
        setPlanCards([]);
        setSelectedPlan(plan);
        setSelectedPlanLabel(description)
        setSelectedPlanStatus(status);

        if (!plan.includes('FREE') && status !== ('CANCELED' || 'ENDED')) {
            // fetch plan cards
            queryClient.fetchQuery(['plan-cards', plan], () => HttpService.get(`/${customer_identification}/billing/${plan}/subscription-payment-card-detail`)).then((value) => {
                if (value.data.element.length > 0) {
                    setPlanCards(value.data.element)

                    queryClient.fetchQuery(['plan-detailed-info', plan], () => HttpService.get(`/${customer_identification}/billing/${plan}/plan-detailed-info`)).then((value) => {
                        if (value.data.element.default_payment_method)
                            setSelectedActivePlanDefaultCardId(value.data.element.default_payment_method)
                    })
                } else {
                    toast.info('No card is provided for this plan.');
                }
            }).catch((err) => {
                toast.error(err.response.data.message || err.message)
            })
        } else
            toast.info('No card is provided for this plan.');
    }

    const handleShowPlanHistory = () => {
        queryClient.fetchQuery(['plan-card-history', selectedPlan], () => HttpService.get(`/${customer_identification}/billing/${selectedPlan}/subscription-detail`)).then((value) => {
            setPlanHistoryData(value.data.element)
            setShowPlanHistoryModal(true)
        }).catch((err) => {
            toast.error(err.response?.data?.message || err.message)
            setShowPlanHistoryModal(false);
        })
    }

    const handleCancelSelectedPlanSubscription = () => {
        Swal.fire({
            title: 'Are you sure?',
            text: 'Be careful! You can not revert your change.',
            showCancelButton: true,
            confirmButtonText: 'Yes, cancel it!',
            cancelButtonText: 'No',
            confirmButtonColor: '#408458',
            focusCancel: true,
        }).then((result) => {
            if (result.isConfirmed) {
                CancelPlanSubscriptionMutation.mutate(null);
            } else {
                Swal.close();
            }
        })
    }

    const handleMakeAsDefault = (paymentMethodId: string) => {
        UpdateSubscriptionCardMutation.mutate(paymentMethodId)
    }

    const handleRemoveCard = (paymentMethodId: string) => {
        RemovePaymentMethodMutation.mutate(paymentMethodId)
    }

    const columns: ColumnDef<Card>[] = [
        {
            accessorKey: 'brand',
            header: () => 'Brand',
        },
        {
            header: () => 'Country',
            accessorKey: 'country',
        },
        {
            header: () => 'Expiry Month',
            accessorKey: 'expMonth',
        },
        {
            header: () => 'Expiry Year',
            accessorKey: 'expYear',
        },
        {
            header: () => 'Card Number',
            accessorKey: 'last4',
            cell: props => {
                return <>
                    <span>**** **** **** {props.row.original.last4}</span>
                </>
            }
        },
        {
            id: 'actions',
            cell: props => {
                return <>
                    {selectedActivePlanDefaultCardId !== props.row.original.paymentMethodId &&
                        <Button size='sm' variant='outline-success' className='me-2 btn-iconic'
                            onClick={() => handleMakeAsDefault(props.row.original.paymentMethodId)}>
                            {(UpdateSubscriptionCardMutation.variables === props.row.original.paymentMethodId && UpdateSubscriptionCardMutation.isLoading) ?
                                <Spinner animation='grow' size='sm' /> : <><i className='bi bi-star me-2'></i><span>Make as default card</span></>}
                        </Button>
                    }
                    {selectedActivePlanDefaultCardId === props.row.original.paymentMethodId &&
                        <Button size='sm' variant='outline-success' className='me-2 btn-iconic'
                            onClick={() => handleMakeAsDefault(props.row.original.paymentMethodId)}>
                            {(UpdateSubscriptionCardMutation.variables === props.row.original.paymentMethodId && UpdateSubscriptionCardMutation.isLoading) ?
                                <Spinner animation='grow' size='sm' /> : <i className='bi bi-star-fill'></i>}
                        </Button>}
                    <Button size='sm' variant='outline-danger' className='btn-iconic'
                        onClick={() => handleRemoveCard(props.row.original.paymentMethodId)}>
                        {(RemovePaymentMethodMutation.variables === props.row.original.paymentMethodId && RemovePaymentMethodMutation.isLoading) ?
                            <Spinner animation='grow' size='sm' /> : <><i
                                className='bi bi-trash me-2'></i><span>Remove</span></>}
                    </Button>
                </>
            }

        },
    ];

    const table = useReactTable({
        data: planCards,
        columns,
        getCoreRowModel: getCoreRowModel(),
    })

    if (isLoading)
        return <Spinner animation="grow" size='sm' />

    if (isError)
        return <Button variant='secondary' onClick={() => refetch()} size='sm'>Reload</Button>

    const planList = res?.data.element.planList;

    return (
        <>
            <Helmet>
                <title>1CDN - Billing</title>
                <meta name="description" content="1CDN - Billing" />
                <link rel="canonical"
                    href={`${process.env.REACT_APP_PAYMENT_SERVICE_REDIRECT_PAGE}/control-panel/billing`} />
            </Helmet>
            <div className='dl-shadow-box edit-preferences p-0 min-vh-100'>
                <div className="d-flex align-items-start justify-content-between px-4 py-3 flex-wrap">
                    <div className='dl-users-chart'>
                        <h5 className='mb-3 fw-bold'>Manage your billing</h5>
                        <Dropdown className='mb-4 rounded-pill'>
                            <Dropdown.Toggle id="dropdown-basic" size='sm' variant='success'>
                                {selectedPlanLabel}
                                <i className='bi bi-chevron-down ms-2'></i>
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                                {planList?.map((item: any, index: number) => (
                                    <Dropdown.Item key={index}
                                        onClick={() => handleFetchPlanCards(item.plan, item.status, item.description)}>{item.description}</Dropdown.Item>
                                ))}
                            </Dropdown.Menu>
                        </Dropdown>
                    </div>
                    <ButtonGroup size='sm' className='flex-wrap billing-group-btn'>
                        {selectedPlan !== 'My plans' && (
                            <>
                                {selectedPlan.includes('FREE') ? (
                                    <Button variant='outline-success'
                                        disabled={!planList || planList.length < 0 || !selectedPlan}
                                        onClick={handleShowPlanHistory}>
                                        My subscriptions details
                                    </Button>
                                ) : (
                                    <>
                                        {selectedPlanStatus === 'CANCELED' ? (
                                            <Button variant='outline-success'
                                                disabled={!planList || planList.length < 0 || !selectedPlan}
                                                onClick={handleShowPlanHistory}>
                                                My subscriptions details
                                            </Button>
                                        ) : (
                                            <>
                                                <Button variant='outline-success'
                                                    disabled={!planList || planList.length < 0 || !selectedPlan}
                                                    onClick={handleShowPlanHistory}>
                                                    My subscriptions details
                                                </Button>

                                                <Button variant="outline-success"
                                                    disabled={!planList || planList.length < 0 || !selectedPlan}
                                                    href={`${process.env.REACT_APP_PAYMENT_SERVICE}/payment/subscription/${selectedPlan}/${process.env.REACT_APP_CDN_IDENTIFICATION}/${customer_identification}/card-updating?&language_code=en&&redirect_page=${process.env.REACT_APP_PAYMENT_SERVICE_REDIRECT_PAGE}/control-panel/billing&template_image_url=${process.env.REACT_APP_PAYMENT_SERVICE_TEMPLATE_IMAGE_URL}`}>
                                                    Add a payment card to this plan
                                                </Button>

                                                <Button variant='outline-success'
                                                    disabled={!planList || planList.length < 0 || !selectedPlan}
                                                    onClick={handleCancelSelectedPlanSubscription}>
                                                    Cancel this plan's subscription
                                                </Button>
                                            </>
                                        )}
                                    </>
                                )}
                            </>
                        )}
                        <Button variant='outline-success' onClick={() => setshowPlansModal(true)}>
                            Subscribe for another plan
                        </Button>
                    </ButtonGroup>
                </div>

                {selectedPlan && selectedPlan !== 'My plans' &&
                    <div className="px-4 py-3 mb-3">
                        Billing Status: <b
                            className={`ms-2 ${selectedPlanStatus === 'ACTIVE' ? 'text-success' : 'text-danger'} `}>{selectedPlanStatus}</b>
                    </div>
                }
                <div className="table-responsive">
                    <Table striped className='dl-table billing-table mb-0'>
                        <thead>
                            {table.getHeaderGroups().map(headerGroup => (
                                <tr key={headerGroup.id}>
                                    {headerGroup.headers.map(header => (
                                        <th key={header.id}>
                                            {header.isPlaceholder
                                                ? null
                                                : flexRender(
                                                    header.column.columnDef.header,
                                                    header.getContext()
                                                )}
                                        </th>
                                    ))}
                                </tr>
                            ))}
                        </thead>
                        <tbody>
                            {table.getRowModel().rows.map(row => (
                                <tr key={row.id} style={{ verticalAlign: 'middle' }}>
                                    {row.getVisibleCells().map(cell => (
                                        <td key={cell.id}>
                                            {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                        </td>
                                    ))}
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </div>

                {/* Plan history */}
                <Modal show={showPlanHistoryModal} onHide={() => setShowPlanHistoryModal(false)} size='lg'
                    className='plans-history-modal'>
                    <Modal.Header closeButton>
                        <Modal.Title>
                            {selectedPlanLabel.toUpperCase()}
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="table-responsive">
                            <Table>
                                <thead>
                                    <tr>
                                        <th>
                                            Payment Date
                                        </th>
                                        <th>
                                            Payment Status
                                        </th>
                                        <th>
                                            Payment Next Date
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {planHistoryData?.map((item: any, index: number) => (
                                        <tr key={index}>
                                            <td>{item.paymentDateTime}</td>
                                            <td>{item.paymentStatusEnum}</td>
                                            <td>{item.paymentNextDate}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                        </div>
                    </Modal.Body>
                </Modal>

                {/* Plans modal */}
                <Modal show={showPlansModal} onHide={() => setshowPlansModal(false)} size='xl'>
                    <Modal.Header closeButton>
                        <Modal.Title>
                            Choose your plan
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <PlansInModal user_identification={customer_identification} isInBilling={true}
                            hasPremiumPlan={['CDN_PREMIUM_BUSINESS_MONTH', 'CDN_PREMIUM_BUSINESS_ANNUAL'].some((r: string) => planList.map((p: any) => p.plan).indexOf(r) >= 0)} />
                    </Modal.Body>
                    {/* <Modal.Footer>
            <Button variant="secondary" onClick={() => setshowPlansModal(false)}>
              Close
            </Button>
          </Modal.Footer> */}
                </Modal>
            </div>
        </>
    )
}

export default Billing