import React, { useState } from "react";
import { Form } from "react-bootstrap";

type BsInputPasswordType = {
  label?: string;
  className?: string;
  isInvalid?: boolean;
  rhfControls: any;
  errorMessage?: string;
  passwordRegexInfo?: {
    description: string;
    regex: string
  },
  startValidating?: boolean,
  isPasswordPatternValid?: boolean
}

const BsInputPassword = (props: BsInputPasswordType) => {
  const [show, setShow] = useState(false);


  return (
    <Form.Group className={props.className + " bsInputPassword"}>
      {props.label ? <Form.Label htmlFor={props.label} className="d-flex align-items-center justify-content-between">{props.label}</Form.Label> : null}
      <Form.Control
        id={props.label}
        className="position-relative"
        type={show ? "text" : "password"}
        isInvalid={props.isInvalid || (props.startValidating && !props.isPasswordPatternValid)}
        isValid={props.isPasswordPatternValid}
        {...props.rhfControls}
      />
      <button type="button" className="show-pass bg-transparent border-0" onClick={() => setShow(!show)}>
        {show ? <i className="bi bi-eye-slash"></i> : <i className="bi bi-eye"></i>}
      </button>
      <Form.Control.Feedback type="invalid">
        {props.errorMessage}
      </Form.Control.Feedback>
      {props.passwordRegexInfo && (
        <Form.Text className={`${props.startValidating && !props.isPasswordPatternValid ? 'text-danger' : props.startValidating && props.isPasswordPatternValid ? 'text-success' : 'text-muted'}`}>
          {props.passwordRegexInfo.description.toLowerCase()}
        </Form.Text>
      )}
    </Form.Group>
  )
}

export default BsInputPassword