import { joiResolver } from '@hookform/resolvers/joi'
import { AxiosError } from 'axios'
import Joi from 'joi'
import { Button, Form, Spinner } from 'react-bootstrap'
import { Helmet } from 'react-helmet-async'
import { useForm } from 'react-hook-form'
import { useMutation, useQuery } from 'react-query'
import { toast } from 'react-toastify'
import useAuth from '../../../../hooks/useAuth'
import HttpService from '../../../../services/http'


const schema = Joi.object({
  message: Joi.string().min(5).max(3500).required().label("Message")
})

const AskQuestionMessage = () => {
  const { customer_identification } = useAuth();

  const { register, handleSubmit, formState: { errors }, reset } = useForm({
    resolver: joiResolver(schema)
  })

  const { isSuccess, data: res } = useQuery('profile', () => HttpService.get(`/${customer_identification}/profile`, {
    auth: HttpService.getToken()
  }), {
    onError: (err: AxiosError<any, any>) => {
      toast.error(err.response?.data?.message || err.message)
    }
  })

  const SendMessageMutation = useMutation<any, AxiosError<any, any>, any>((data) => HttpService.post(`/${customer_identification}/profile/message`, data, {
    auth: HttpService.getToken()
  }), {
    onSuccess: () => {
      toast.success('Message is sent')
      reset({
        message: ''
      })
    },
    onError: (err) => {
      toast.error(err.response?.data?.message || err.message)
    }
  })

  const onSubmit = (values: any) => {
    if (isSuccess)
      SendMessageMutation.mutate({ message: values.message, customer_email: res?.data.element.user_email })
  }

  return (
    <>
      <Helmet>
        <title>1CDN - Ask question</title>
        <meta name="description" content="1CDN - Ask question" />
        <link rel="canonical" href={`${process.env.REACT_APP_PAYMENT_SERVICE_REDIRECT_PAGE}/control-panel/messages`} />
      </Helmet>
      <div className='dl-shadow-box'>
        <h5 className="fw-bold">
          Any questions?
        </h5>
        <p className="text-black-50">
          Please write down your questions and problems, and we will answer you shortly.
        </p>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Form.Group className='mb-3'>
            <Form.Label htmlFor='message'>
              Your message:
            </Form.Label>
            <Form.Control id='message' as={'textarea'} isInvalid={!!errors.message} rows={5} {...register('message')} />
            <Form.Control.Feedback type="invalid">
              {errors.message?.message}
            </Form.Control.Feedback>
          </Form.Group>
          <Button type='submit' variant='success'>
            {SendMessageMutation.isLoading ? <Spinner animation='grow' size='sm' /> : "Send my message"}
          </Button>
        </Form>
      </div>
    </>
  )
}

export default AskQuestionMessage