import React, { useEffect } from "react";
import { Navigate } from "react-router-dom";
import Contexts from '../contexts/index'
import useAppLocalStorage from "../hooks/useAppLocalStorage";
import { authProvider } from "../services/auth";
import HttpService from "../services/http";

const AuthProvider = ({ children }: { children: React.ReactNode }) => {
    const localStorage = useAppLocalStorage()
    let [user, setUser] = React.useState<any>(null);
    let [customer_identification, setCustomer_identification] = React.useState<string>('');

    useEffect(() => {
        let item = false;

        let sessionData: any = localStorage.getItem('_cdn_service');

        if (sessionData) {
            item = true;

            const { exp, rememberMe, ...rest } = JSON.parse(sessionData);

            if (item && new Date(exp) >= new Date()) {
                return authProvider.login(() => {
                    setUser(rest);
                    setCustomer_identification(rest.userIdentification)
                });
            } else
                <Navigate to={'/logout'} />
        }

        return () => {
            item = false
        }
    }, [localStorage])

    let login = (data: any) => {
        let exp = new Date();

        if (data.rememberMe) {
            exp.setDate(exp.getDate() + 7);

            localStorage.setItem('_cdn_service', JSON.stringify({ ...data, exp }))
        } else {
            exp.setDate(exp.getDate() + 1);

            localStorage.setItem('_cdn_service', JSON.stringify({ ...data, exp }))
        }

        HttpService.setToken(data);

        return authProvider.login(() => {
            setUser(data);
            setCustomer_identification(data.userIdentification)
        });
    };

    let logout = () => {
        return authProvider.logout(() => {
            setUser(null);
        });
    };

    let value = { user, customer_identification, setUser, login, logout };

    return <Contexts.AuthContext.Provider value={value}>{children}</Contexts.AuthContext.Provider>;
}

export default AuthProvider