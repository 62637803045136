import { useEffect, useState } from "react";
import { Button, Form, Spinner } from "react-bootstrap";
import { useMutation } from "react-query";
import { useForm } from "react-hook-form";
import { joiResolver } from "@hookform/resolvers/joi";
import * as Joi from "joi";
import { Link, useNavigate } from "react-router-dom";
import HttpService from "../../services/http";
import useAuth from "../../hooks/useAuth";
import { toast } from "react-toastify";
import { AxiosError } from "axios";
import { Helmet } from "react-helmet-async";

const schema = Joi.object({
  user_email: Joi.string().required().label("Email"),
}).required();

export const Timer = ({
  setShowTimer,
  ttl,
}: {
  setShowTimer: (bool: boolean) => void;
  ttl: number;
}) => {
  const [remain, setRemain] = useState(ttl);

  useEffect(() => {
    const interval = setInterval(() => {
      setRemain(remain - 1);

      if (remain <= 0) setShowTimer(false);
    }, 1000);

    return () => clearInterval(interval);
  }, [remain, setShowTimer, ttl]);

  return <span>{remain} seconds</span>;
};

const ForgotPassword = () => {
  const navigate = useNavigate();
  const auth = useAuth();

  const from = "/control-panel";

  useEffect(() => {
    let cleaner = true;

    if (cleaner && auth.user) navigate(from);

    return () => {
      cleaner = false;
    };
  }, [auth, from, navigate]);

  const [showTimer, setShowTimer] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: joiResolver(schema),
  });

  const ForgotPasswordMutation = useMutation<
    void,
    AxiosError<any, any>,
    string,
    any
  >(
    (user_email) =>
      HttpService.get(`auth/password?email_address=${user_email}`),
    {
      onError: (err) => {
        toast.error(err.response?.data.message || err.message);

        setShowTimer(false);
      },
    }
  );

  const onSubmit = (data: any) => {
    ForgotPasswordMutation.mutate(encodeURIComponent(data.user_email), {
      onSuccess: () => {
        setShowTimer(true);

        toast.success("The reset link has been sent to your email address.");
      },
    });
  };

  return (
    <>
      <Helmet>
        <title>1CDN - Forgot password</title>
        <meta name="description" content="1CDN - Forgot password" />
        <link
          rel="canonical"
          href={`${process.env.REACT_APP_PAYMENT_SERVICE_REDIRECT_PAGE}/forgot-password`}
        />
      </Helmet>

      <div className="d-flex justify-content-between align-items-center mb-4">
        <h1 className="fs-4 fw-bold">Forgot your password?</h1>
        <small>
          Back to{" "}
          <Link to="/login" className="text-black">
            Login
          </Link>
        </small>
      </div>

      <Form onSubmit={handleSubmit(onSubmit)}>
        <Form.Group className="mb-3">
          <Form.Label htmlFor="user_email">Email</Form.Label>
          <Form.Control
            id="user_email"
            type="email"
            isInvalid={!!errors.user_email}
            {...register("user_email")}
          />
          <Form.Control.Feedback type="invalid">
            {errors.user_email?.message}
          </Form.Control.Feedback>
        </Form.Group>

        <Button
          variant="primary"
          type="submit"
          className="w-100 rounded-pill auth-btn"
          size="lg"
          disabled={showTimer || ForgotPasswordMutation.isLoading}
        >
          {ForgotPasswordMutation.isLoading ? (
            <Spinner animation="grow" />
          ) : showTimer ? (
            <Timer setShowTimer={setShowTimer} ttl={120} />
          ) : (
            "Send Link"
          )}
        </Button>
      </Form>
    </>
  );
};

export default ForgotPassword;
