import { useState } from 'react'
import { Col, Row, Modal, Button } from 'react-bootstrap'
import { useNavigate } from "react-router-dom"
import useAuth from '../hooks/useAuth'
import LatestUploadedFiles from '../components/pages/dashboard/LatestUploadedFiles'
import TotalUploadedFiles from '../components/pages/dashboard/TotalUploadedFiles'
import { Helmet } from 'react-helmet-async'
import useAppLocalStorage from '../hooks/useAppLocalStorage'

const Dashboard = () => {
  const localStorage = useAppLocalStorage()
  const { user, setUser } = useAuth()
  const navigate = useNavigate();

  const [showWeakPassInfoModal, setShowWeakPassInfoModal] = useState(user.requiredPasswordUpdate || false)

  const renderWeakPassInfo = () => {
    return <div className='text-center'>
      <img loading='lazy' src={'https://cdn.pinkswan.ch/application/CDN/22103012353888_weak_password_1.svg'} className='img-fluid mb-4 mt-3 w-50' alt='welcome to dashboard' />
      <h2 className='text-dl-blue'>
        Your password is weak!
      </h2>
      <p>
        To protect your account, change your password to a stronger one.
      </p>
      <Button variant="success" className='px-2 py-2 rounded text-white w-50 mx-auto mt-2' onClick={() => goToLevelUpPassword()}>
        Change password
      </Button>
    </div>;
  }

  const goToLevelUpPassword = () => {
    handleCancel();
    navigate('/level-up-password', { replace: true });
  }

  const handleCancel = () => {
    setShowWeakPassInfoModal(false);
    setUser({ ...user, requiredPasswordUpdate: false });

    let parsedCredential = JSON.parse(localStorage.getItem('_cdn_service') as string);

    if (parsedCredential) {
      parsedCredential.requiredPasswordUpdate = false;
      localStorage.setItem(
        '_cdn_service',
        JSON.stringify(parsedCredential)
      );
    }
  }

  return (
    <>
      <Helmet>
        <title>Control panel</title>
        <meta name="description" content="Control panel" />
        <link rel="canonical" href={`${process.env.REACT_APP_PAYMENT_SERVICE_REDIRECT_PAGE}/control-panel`} />
      </Helmet>
      <Row className='mb-4'>
        <Col sm={5} md={5} xs={12} className='mb-sm-0 mb-3'>
          <TotalUploadedFiles />
        </Col>
        <Col sm={7} md={7} xs={12}>
          <LatestUploadedFiles />
        </Col>
      </Row>

      {/* Change password to stronge one modal */}
      <Modal centered show={showWeakPassInfoModal} onHide={() => handleCancel()} size={undefined}>
        <Modal.Header closeButton className='border-0' />
        <Modal.Body>
          {renderWeakPassInfo()}
        </Modal.Body>
      </Modal>
    </>
  )
}

export default Dashboard