import { useEffect, useState } from "react"
import { Button, Form, Spinner } from "react-bootstrap"
import { Link, useNavigate, useSearchParams } from "react-router-dom"
import { useForm } from "react-hook-form";
import { joiResolver } from '@hookform/resolvers/joi';
import * as Joi from 'joi';
import useAuth from "../../hooks/useAuth";
import { useMutation } from "react-query";
import HttpService from "../../services/http";
import BsInputPassword from "../../components/public/BsInputPassword";
import { toast } from "react-toastify";
import { AxiosError } from "axios";
import { Helmet } from "react-helmet-async";
import useAppLocalStorage from "../../hooks/useAppLocalStorage";

const schema = Joi.object({
  new_password: Joi.string().required().label("New password"),
  new_confirm_password: Joi.any().valid(Joi.ref("new_password")).required().messages({ "any.only": "Those passwords didn't match. Try again." }).label("Confirm new password"),
});

const ResetPassword = () => {
  const localStorage = useAppLocalStorage()
  const auth = useAuth();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const from = "/control-panel";

  useEffect(() => {
    let cleaner = true;

    if (cleaner && auth.user)
      navigate(from)

    if (!searchParams.get('user_identification') && !searchParams.get('token'))
      navigate('/')

    return () => {
      cleaner = false;
    }
  }, [auth, from, navigate, searchParams])

  const [apiErrors, setApiErrors] = useState<any>({});

  const { register, handleSubmit, formState: { errors }, setError } = useForm({
    resolver: joiResolver(schema)
  });

  const ResetPasswordMutation = useMutation<any, AxiosError<any, any>>(
    (data: any) => HttpService.patch(`auth/${searchParams.get('user_identification')}/${searchParams.get('token')}/password`, data),
    {
      onError: (err) => {
        toast.error(err.response?.data.message || err.message)
      }
    }
  )

  const PasswordCheckMutation = useMutation<any, AxiosError<any, any>, { password: string, level?: string | null }>(
    (data) => HttpService.post('/auth/check-password', data),
    {
      onError: (err) => {
        toast.error(err.response?.data.message || err.message)

        setApiErrors({ ...apiErrors, new_password: err.response?.data.message })
        setError('new_password', err.response?.data.message)
      }
    }
  )

  const onSubmit = (data: any) => {
    PasswordCheckMutation.mutate({
      password: data.new_password,
      level: localStorage.getItem('_cdn_password_level')
    }, {
      onSuccess: () => {
        ResetPasswordMutation.mutate(data, {
          onSuccess: (res) => {
            toast.success("Password reset successfully")

            navigate('/login')
          },
        })
      }
    })
  }

  return (
    <>
      <Helmet>
        <title>1CDN - Reset password</title>
        <meta name="description" content="1CDN - Reset password" />
        <link rel="canonical" href={`${process.env.REACT_APP_PAYMENT_SERVICE_REDIRECT_PAGE}/reset-password`} />
      </Helmet>

      <div className="d-flex justify-content-between align-items-center mb-4">
        <h1 className="fs-4 fw-bold mb-0">Reset password</h1>
        <small>Back to <Link to="/login" className="text-black">Login</Link></small>
      </div>

      <Form onSubmit={handleSubmit(onSubmit)}>
        <BsInputPassword label="New Password" isInvalid={!!errors.new_password || !!apiErrors.new_password} errorMessage={errors.new_password?.message || apiErrors.new_password} className={"mb-3"} rhfControls={register("new_password")} />

        <BsInputPassword label="Re-enter Password" isInvalid={!!errors.new_confirm_password} errorMessage={errors.new_confirm_password?.message} className={"mb-3"} rhfControls={register("new_confirm_password")} />

        <Button variant="primary" type="submit" className="w-100 rounded-pill auth-btn" size="lg" disabled={ResetPasswordMutation.isLoading || PasswordCheckMutation.isLoading}>
          {ResetPasswordMutation.isLoading ? <Spinner animation="grow" /> : "Reset"}
        </Button>
      </Form>
    </>
  )
}

export default ResetPassword