import React from "react";
import { AuthContextType, StorageContextType, UiContextType } from "./types";

let AuthContext = React.createContext<AuthContextType>(null!);
let StorageContext = React.createContext<StorageContextType>(null!);
let UiContext = React.createContext<UiContextType>(null!);

const Contexts = {
  AuthContext,
  StorageContext,
  UiContext
};

export default Contexts;
