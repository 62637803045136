import React from 'react'
import { Link } from 'react-router-dom'

const Section2 = () => {
  return (
    <section className='section2 cs-p d-flex flex-column text-center align-items-center justify-content-center' id='intro'>
      <img loading="lazy" src="https://cdn.pinkswan.ch/application/CDN/cdn_global_deliverd.svg" alt="1CDN" className='img-fluid' width={250} height={150} title='1CDN bring content closely' />
      <h2 className='fs-1 mb-0 mt-4'>
        No matter <span>where you are</span>
        <br />
        Your media is available, close to your users.
      </h2>
      <div className="d-flex align-items-center justify-content-center mt-3">
        <Link to={'/register'} className='btn btn-success rounded-pill me-2'>
          Get started for free
        </Link>
        <a href='#features' className='btn btn-outline-success rounded-pill'>
          <i className='bi bi-arrow-down me-2'></i>
          Learn more
        </a>
      </div>
    </section>
  )
}

export default Section2