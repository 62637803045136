import { useEffect } from 'react'
import { useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom'
import useAppLocalStorage from '../../hooks/useAppLocalStorage';
import useAuth from '../../hooks/useAuth';
import useUi from '../../hooks/useUi';

const Logout = () => {
    const localStorage = useAppLocalStorage()
    const auth = useAuth();
    const ui = useUi();

    const navigate = useNavigate();

    const queryClient = useQueryClient();

    useEffect(() => {
        localStorage.removeItem("_cdn_service")

        auth.logout();
        ui.clear();

        queryClient.clear();

        navigate('/')
    }, [auth, navigate, queryClient, localStorage, ui])


    return null
}

export default Logout