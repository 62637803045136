import { Link } from "react-router-dom"

const NotFound = () => {
  return (
    <>
      <h1 className="audiowide">
        404!
      </h1>
      <h4 className="prompt fw-bold">
        No fish found
      </h4>
      <img loading="lazy" src="https://cdn.pinkswan.ch/application/CDN/22102206285110_404_cdn.svg" alt="1CDN" className="img-fluid my-5 d-block mx-auto" />
      <Link to={'/'} className="btn btn-lg rounded-pill pink">
        <svg width="24" height="14" viewBox="0 0 24 14" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M6.20737 12.8819C6.39362 12.6946 6.49816 12.4411 6.49816 12.1769C6.49816 11.9128 6.39362 11.6593 6.20737 11.4719L2.57736 7.88194L22.9974 7.88194C23.2626 7.88194 23.517 7.77658 23.7045 7.58904C23.892 7.40151 23.9974 7.14715 23.9974 6.88194C23.9974 6.61672 23.892 6.36237 23.7045 6.17483C23.517 5.98729 23.2626 5.88194 22.9974 5.88194L2.58736 5.88194L6.20737 2.26194C6.3011 2.16897 6.37549 2.05837 6.42626 1.93651C6.47703 1.81465 6.50317 1.68395 6.50317 1.55194C6.50317 1.41993 6.47703 1.28922 6.42626 1.16736C6.37549 1.0455 6.3011 0.934899 6.20737 0.841936C6.02 0.655685 5.76655 0.551143 5.50237 0.551143C5.23818 0.551143 4.98473 0.655685 4.79737 0.841936L0.877361 4.75194C0.315558 5.31444 -3.10167e-07 6.07693 -2.75416e-07 6.87194C-2.40666e-07 7.66694 0.315558 8.42944 0.877361 8.99194L4.79737 12.8819C4.98473 13.0682 5.23818 13.1727 5.50237 13.1727C5.76655 13.1727 6.02001 13.0682 6.20737 12.8819Z" fill="#3B3B3B" />
        </svg>
        <span className="ms-2">
          Go home
        </span>
      </Link>
    </>
  )
}

export default NotFound