import { Col, Container, Row } from 'react-bootstrap'
import { Link } from 'react-router-dom'

const Section3 = () => {
  return (
    <section className='section3 cs-p'>
      <Container fluid='lg'>
        <p id='features' className='text-end text-white-50 mb-4'>
          Features
        </p>
        <div className="sec3-1">
          <Row className='align-items-center'>
            <Col lg={5} md={6} className="mb-md-0 mb-3">
              <img loading="lazy" src="https://cdn.pinkswan.ch/application/CDN/cdn_deliver_fast.svg" alt="1CDN" className='img-fluid' width={300} height={150} title='1CDN deliver content very fast' />
            </Col>
            <Col lg={7} md={6}>
              <h3 className='fs-1'>
                <span>
                  Many servers are ready around the world
                </span>
                to deliver your content as quickly as possible.
              </h3>
              <p className="mb-0">
                No matter where your users are, we're here to get you as close to them as possible. We store your files on a secure platform, and no matter where your users' requests are located, we will serve your files from the closest server.
              </p>
              <Link to={'/register'} className='btn btn-success rounded-pill mt-4'>
                Get started for free
              </Link>
            </Col>
          </Row>
        </div>
        <div className="sec3-2">
          <Row className='text-center'>
            <Col xs={12}>
              <h3 className='fs-1'>
                We support<span> most files !</span>
              </h3>
              <img loading="lazy" src="https://cdn.pinkswan.ch/application/CDN/cdn_teleporting.svg" alt="1CDN" className='img-fluid' width={250} height={150} title='1CDN - Support any format' />
            </Col>
          </Row>
        </div>
        <div className="sec3-3">
          <Row className='align-items-center'>
            <Col lg={7} md={8} sm={{ order: 1 }} xs={{ order: 2 }}>
              <h3 className='fs-1'>
                <span>
                  Delete some
                </span>
                or all your files if you want.
              </h3>
              <p>
                In addition to being able to protect your files on a secure and fast platform, you can delete them at any time, whether it is one or all files. After deletion, your files never existed. No trace !
              </p>
              <Link to={'/register'} className='btn btn-success rounded-pill me-2'>
                Get started for free
              </Link>
              <a href='#about' className='btn btn-outline-white rounded-pill'>
                About us
                <i className='bi bi-arrow-down ms-2'></i>
              </a>
            </Col>
            <Col lg={5} md={4} sm={{ order: 2 }} xs={{ order: 1 }} className="mb-md-0 mb-3">
              <img loading="lazy" src="https://cdn.pinkswan.ch/application/CDN/cdn_removing_files.svg" alt="1CDN" className='img-fluid' width={153} height={150} title='1CDN - Delete file' />
            </Col>
          </Row>
        </div>
      </Container>
    </section>
  )
}

export default Section3