import React from 'react';
import HomeLayoutFooter from "../home/HomeLayoutFooter";
import HomeLayoutNavbar from "../home/HomeLayoutNavbar";
import { Container } from "react-bootstrap";
import { Helmet } from 'react-helmet-async';

const Terms = () => {
  return (
    <React.Fragment>
      <Helmet>
        <title>1CDN - Terms and conditions</title>
        <meta name="description" content="1CDN - Terms and conditions" />
        <link rel="canonical" href={`${process.env.REACT_APP_PAYMENT_SERVICE_REDIRECT_PAGE}/tos`} />
      </Helmet>
      <HomeLayoutNavbar />
      <section className='mt-5 mb-5'>
        <Container fluid={'lg'}>
          <h1 className='text-center mt-4'>
            Terms of service
          </h1>
          <div className='mt-5 mb-5'>
            Please read these terms of service (these “terms”) carefully. These terms are a binding contract
            for the use of 01cdn (“1CDN”) services.
            By accessing or using 1CDN services you are accepting these terms (on behalf of yourself or
            the entity that you represent) and you represent and warrant that you have the right, authority,
            and capacity to enter into these terms (on behalf of yourself or the entity that you represent
            and its affiliates). If you do not agree to be bound by all of the provisions of these terms, do
            not access or use 1CDN’s services.
            Pricing for 1CDN’s services is available at https://www.1cdn.app/plans. Documentation of
            1CDN’s services is available in dashboard. Before accepting these terms and using 1CDN’s
            services, please review the information at each of these online locations.
            These terms were last updated on septembre 3, 2022. Prior versions of 1CDN’s terms of
            service are available at https://1cdn.app/tos
          </div>
          <h2>AGREEMENT</h2>
          <ul>
            <li>These 1CDN terms of service (these “terms”) are made by and between the party on whose
              behalf they are accepted (“subscriber”) and 1CDN, inc. (“1CDN”) and are effective as
              of the date they are accepted by subscriber. The complete subscription agreement including
              these terms (the “agreement”) grants subscriber a limited subscription to use 1CDN’s
              internet intermediary, support, professional, or other services (the “services”).
            </li>
          </ul>
          <h2>TRIAL ACCOUNTS</h2>
          <ul>
            <li>Unpaid trial and developer accounts are made available for subscribers to test the
              functionality of the services without incurring payment obligations (“trial accounts”). Once
              a subscriber’s use of a trial account exceeds an equivalent of normal usage, 1CDN may
              suspend the trial account unless subscriber converts its trial account to a paid account.
              All trial accounts are provided “as is” and “as available” without any warranty of any kind.
              Trial accounts may be suspended, terminated, or discontinued at any time and for any reason
              (or no reason). 1CDN disclaims all obligation and liability under the agreement for any
              harm or damage arising out of or in connection with a trial account, including any
              obligation or liability with respect to subscriber data and any disruption to subscriber’s
              or third party services configured to work with the trial account. Any configurations or
              subscriber data entered into a trial account, and any customizations made to a trial account
              by or for subscriber, may be permanently lost if the trial account is suspended, terminated,
              or discontinued.
            </li>
          </ul>
          <h2>1CDN'S OBLIGATIONS</h2>
          <ul>
            <li>
              <ul>
                <li><b>Services</b> - 1CDN will make the services available to subscriber according
                  to one or more online or written ordering documents which incorporate the agreement.
                </li>
                <li><b>Compliance with laws</b> - 1CDN shall comply with all laws and governmental
                  regulations applicable to the services.
                </li>
                <li><b>Personnel and performance</b> - 1CDN will be responsible for the performance
                  of its personnel (including employees and contractors) and their compliance with the
                  agreement.
                </li>
                <li><b>Documentation</b> - 1CDN will make online documentation in your dashboard.
                </li>
                <li><b>Security measures</b> - 1CDN will maintain the security measures consistent
                  with industry standard practices and as described in the documentation. 1CDN
                  will process, transmit, and store the data and information provided to the services
                  by or on behalf of subscriber (including content, requests, configurations and
                  credentials) (“subscriber data”) only according to the agreement and the
                  documentation. The services, independent of subscriber data, will not transmit code,
                  files, scripts, agents, or programs intended to do harm, including, viruses, worms,
                  time bombs, and trojan horses (“malicious code”).
                </li>
                <li><b>Data processing</b> - 1CDN will process, transmit and store personal data
                  present in subscriber data in accordance with the data processing terms. 1CDN
                  shall not sell nor disclose any subscriber data. 1CDN shall not disclose any
                  data insights in a manner that identifies subscriber.
                </li>
              </ul>
            </li>
          </ul>
          <h2>SUBSCRIBER'S OBLIGATIONS</h2>
          <ul>
            <li><b>Subscriber data</b> - As between 1CDN and subscriber, subscriber is responsible for
              subscriber data and the provision of subscriber data to the services according to the
              agreement.
            </li>
            <li><b>Personnel and performance</b> - Subscriber shall be responsible for the performance of
              its personnel (including employees and contractors) in compliance with the agreement.
              Subscriber enters into the agreement on behalf of its affiliates that make use of the
              services.
            </li>
            <li><b>Third party reports</b> - As an intermediary, 1CDN may receive notices from third
              parties (“reporters”) regarding subscriber data or subscriber’s use of the services
              (“reports”). 1CDN will forward all reports directly to an email address provided by
              subscriber designated to receive reports (the “abuse contact”). Upon receipt of a report,
              subscriber will (i) acknowledge receipt of such report to 1CDN within two (2) business
              days of receipt and (ii) address the report within five (5) business days with the reporter
              including informing the reporter that subscriber (and not 1CDN) is the appropriate party
              to address the matter. 1CDN may provide the abuse contact to a reporter inquiring about
              subscriber data or subscriber’s use of the services.
            </li>
            <li><b>Responsibilities</b> - Subscriber (a) shall comply with the 1CDN acceptable use
              policy; (b) shall use the services according to the documentation; (c) shall be responsible
              for using commercially reasonable efforts to prevent unauthorized access to or use of the
              services; (d) shall promptly notify 1CDN of any unauthorized access or use of the
              services; (e) shall respond to reports in accordance with section 4.4 (third party reports)
              and take reasonably appropriate action to resolve the reported matter; (f) shall not use the
              services to store, transmit or display subscriber data for fraudulent purposes or in
              violation of applicable laws and governmental regulations; (g) shall not make the services
              available to, or use the services for the benefit of, anyone other than subscriber’s own
              personnel or end users; (h) shall not use the services to store, transmit or display
              malicious code; (i) shall not interfere with or disrupt the integrity or performance of the
              services or any third-party technology contained therein; (j) shall not attempt to gain
              unauthorized access to any of 1CDN’s systems or networks; (k) shall not permit direct or
              indirect access to or use the services in a way that circumvents a usage limit; (l) shall
              not sell, resell, license, sublicense, distribute, redistribute, rent, or lease the services
              except as integrated with its own offerings that provide additional functionality to its end
              users; (m) shall not copy the services or any part, feature, function, or user interface
              thereof; (n) shall not access the services or use the documentation to develop or offer a
              competitive product or service; (o) shall not reverse engineer the services (to the extent a
              restriction on reverse engineering is permitted by applicable law) and (p) upon the
              termination of an applicable service order remove or delete any 1CDN software from any
              subscriber environment into which it has been installed.
            </li>
            <li><b>Service notices</b> - If 1CDN becomes aware that subscriber may violate subscriber’s
              obligations under this section 4 (subscriber’s obligations), 1CDN will notify the abuse
              contact by email (the “service notice”) and request subscriber to take reasonably
              appropriate action, including cessation of problematic usage, changing a configuration,
              updating credentials or removing applicable subscriber data. If subscriber fails to comply
              with a service notice within the time period set forth in the service notice, 1CDN may
              suspend subscriber’s access to the services until the requested action is taken. If
              subscriber fails to take the required action within ten (10) days or on two or more
              occasions during any rolling twelve (12) month period, 1CDN may terminate the agreement
              immediately for cause. All limitations of access, suspensions, and terminations for cause
              shall be made in 1CDN’s sole discretion and 1CDN shall not be liable to subscriber
              or any third party for any termination of subscriber’s account or access to the services.
            </li>
          </ul>
          <h2>TERM AND TERMINATION</h2>
          <ul>
            <li><b>Term</b> - This agreement is effective during the term of any service order that
              incorporates it. The term of a service order shall be specified in the service order.
              Service orders shall renew for successive terms, unless (a) subscriber is in breach of this
              agreement or (b) subscriber gives 1CDN at least one (1) month’s notice of nonrenewal at
              the end of the applicable term.
            </li>
            <li><b>Termination for cause</b> - In addition to 1CDN’s right to terminate the entire
              agreement under section 4.5 (service notices), subscriber or 1CDN may terminate the
              entire agreement for cause upon 30 days’ written notice to the other of a material breach if
              the breach remains uncured at the expiration of the notice period.
            </li>
            <li><b>Survival</b> - Any term or condition that by its nature is clearly intended to survive
              the expiration or termination of the agreement, shall survive any expiration or termination
              of the agreement, including sections 4.4(m), (n), (o) and (p) (subscriber’s obligations),
              section 7.1 (fees), section 7.5 (refund or payment upon termination), section 8
              (confidentiality), section 9 (licenses and proprietary rights), section 13 (limitation of
              liability), section 14 (exclusion of consequential and related damages), section 16
              (indemnification) and section 22 (authorized reseller).
            </li>
          </ul>
          <h2>BETA SERVICES</h2>
          <ul>
            <li>From time to time, 1CDN may offer services identified as beta, pilot, developer preview,
              evaluation or by a description of similar import (“beta services”). Subscriber may accept or
              decline beta services in its discretion. If accepted, beta servicesare provided only for
              evaluation purposes. 1CDN may discontinue beta services at any time in its sole
              discretion and may never make beta services generally available. All beta services are
              provided “as is” and “as available” without any warranty of any kind. 1CDN disclaims all
              obligation and liability under the agreement for any harm or damage arising out of or in
              connection with a beta service. Any configurations or subscriber data entered into beta
              services, and any customizations made to beta services by or for subscriber, may be
              permanently lost.
            </li>
          </ul>
          <h2>FEES AND PAYMENT</h2>
          <ul>
            <li><b>Fees</b> - Subscriber will pay all fees specified in service orders and provide accurate
              and updated billing contact information. Minimum commitments in service orders are (a) based
              on services purchased and not actual usage; (b) non-cancelable; and (c) cannot be decreased
              during the specified term. Fees paid for minimum commitments and actual usage are not
              refundable. Subscriber’s payments of fees are neither (x) contingent on the delivery of any
              future functionality or features nor (y) dependent on statements not set forth in the
              agreement.
            </li>
            <li><b>Invoicing terms</b> - If the service order specifies that payment will occur by a method
              other than a credit card, subscriber will provide a purchase order number in the applicable
              amount (or reasonable alternative proof of subscriber’s ability to pay the fees specified in
              a service order), and promptly notify 1CDN of any changes necessary for payment of an
              invoice. 1CDN will invoice subscriber either monthly or according to the billing
              frequency stated in the service order. Invoices to be paid by credit card are due on the
              invoice date, all other invoices are due net 30 days from the invoice date. If any invoiced
              amount is not received by 1CDN by the due date, then without limiting 1CDN’s rights
              or remedies: (a) those charges may accrue late interest at the rate of 1.5% of the
              outstanding balance per month, or the maximum rate permitted by law, whichever is lower, (b)
              1CDN may condition future subscription renewals and service orders on shorter payment
              terms or (c) provide a notice to subscriber with a notice of non-renewal for all outstanding
              service orders. If subscriber is paying for services by credit card, subscriber will provide
              1CDN with valid credit card information and promptly notify 1CDN of any changes
              necessary to charge the credit card. The provision of credit card information to 1CDN
              authorizes 1CDN to charge the credit card for all services specified in a service order,
              and any renewal subscription.
            </li>
            <li><b>Suspension of service and acceleration</b> - If any amount owing by subscriber is 30 or
              more days overdue (or 10 or more days overdue in the case of invoices to be paid by credit
              card), 1CDN may, without limiting any rights and remedies, accelerate subscriber’s
              unpaid fee obligations to become immediately due and payable, not renew any service order,
              and suspend the provision of services to subscriber until the overdue amounts are paid in
              full. 1CDN will give subscriber at least 10 days’ prior notice that its account is
              overdue, in accordance with section 18 (manner of giving notice), before suspending services
              to subscriber.
            </li>
            <li><b>Payment disputes</b> - 1CDN will not exercise any rights to suspend services,
              accelerate payments, impose late charges or change payment terms under section 7.2
              (invoicing terms) with respect to an overdue amount for so long as subscriber is disputing
              the overdue amount in good faith. The parties shall cooperate diligently to resolve the
              dispute.
            </li>
            <li><b>Refund or payment upon termination</b> - If subscriber terminates the agreement in
              accordance with section 5.2 (termination for cause), 1CDN will refund any prepaid fees
              covering the remainder of the term of all service orders after the effective date of
              termination. If the agreement is terminated by 1CDN in accordance with section 5.2
              (termination for cause), subscriber will pay any unpaid fees covering the remainder of the
              term of all service orders. In no event will termination relieve subscriber of its
              obligation to pay any fees payable for the period prior to the effective date of
              termination.
            </li>
            <li><b>Taxes</b> - Fees for services do not include any taxes, levies, duties, or similar
              governmental assessments of any nature, including, for example, value-added, sales, use, or
              withholding taxes assessable by any jurisdiction whatsoever (collectively, “taxes”).
              Subscriber is responsible for paying all taxes associated with its service orders. If
              1CDN is obligated by law to pay or collect taxes for which subscriber is responsible,
              1CDN will invoice subscriber and subscriber will pay that amount unless subscriber can
              provide a valid tax exemption certificate authorized by the appropriate taxing authority.
              Subscriber will provide 1CDN any information 1CDN reasonably requests to determine
              whether 1CDN is obligated to collect taxes. 1CDN is solely responsible for taxes
              assessable against its income, property, and employees.
            </li>
          </ul>
          <h2>CONFIDENTIALITY</h2>
          <ul>
            <li><b>Confidential information</b> - “confidential information” means all information about the
              relationship created by the agreement disclosed by a party (“discloser”) to the other party
              (“recipient”), whether orally or in writing, that is designated as confidential or, given
              the nature of the information and the circumstances of disclosure, should be understood to
              be confidential. Confidential information of each party includes the terms and conditions of
              the agreement and all service orders, including pricing, as well as business and marketing
              plans, technology and technical information, product plans and designs, and business
              processes disclosed in connection with the agreement. Confidential information does not
              include information that: (a) is at the time of disclosure, or later becomes, generally
              known to the public through no fault of recipient; (b) was known to the recipient with no
              obligation of confidentiality prior to disclosure by discloser, as proven by records of
              recipient; (c) is disclosed to recipient by a third party who did not directly or indirectly
              obtain the information subject to any confidentiality obligation; or (d) is at any time
              independently developed by recipient as proven by records of recipient.
            </li>
            <li><b>Protection of confidential information</b> - Except as provided in section 8.3 (compelled
              disclosure) recipient shall not disclose or otherwise make available any confidential
              information of the discloser to anyone except those of its employees, attorneys, agents and
              consultants who: (a) need to know the confidential information in connection with the
              purpose of the agreement and (b) who have previously agreed to be bound by confidentiality
              obligations no less stringent than those in the agreement. Each party shall safeguard all
              confidential information of the other party with at least the same degree of care (but no
              less than reasonable care) as it uses to safeguard its own confidential information.
            </li>
            <li><b> Compelled disclosure</b> - If the recipient is compelled by law to disclose confidential
              information of the discloser, then to the extent legally permitted, recipient shall provide
              the discloser with prior notice of the compelled disclosure and reasonable assistance, at
              discloser’s cost, if the discloser wishes to contest the compelled disclosure. Any compelled
              disclosure shall be limited to the extent required, and shall be subject to confidentiality
              protections to the extent practicable. If the recipient is compelled by law to disclose the
              discloser’s confidential information as part of a civil proceeding to which the discloser is
              a party, and the discloser is not contesting the disclosure, the discloser will reimburse
              the recipient for its reasonable cost of compiling and providing secure access to that
              confidential information.
            </li>
          </ul>
          <h2>LICENCES AND PROPRIETARY RIGHTS</h2>
          <ul>
            <li><b>Subscriber data</b> - Subscriber grants 1CDN a worldwide, non-exclusive, revocable,
              and limited license during the term of subscriber’s use of the services to process,
              transmit, and store subscriber data, and to interoperate with any non-1CDN services as
              necessary in order for 1CDN to provide the services in accordance with the agreement.
              Subject to this limited license, 1CDN acquires no right, title, or interest from
              subscriber under the agreement in or to subscriber data.
            </li>
            <li><b>Feedback</b> - Subscriber grants to 1CDN a worldwide, perpetual, irrevocable,
              royalty-free permission to use and incorporate into the services any suggestion, enhancement
              request, recommendation, correction, or other feedback provided by subscriber.
            </li>
            <li><b>Deliverables</b> - 1CDN grants subscriber a worldwide, perpetual, non-exclusive,
              non-transferable, royalty-free license to use for its internal business purposes anything
              developed by 1CDN for subscriber and delivered by 1CDN to subscriber in connection
              with support or professional services (“deliverables”). Subject to subscriber’s ownership of
              its proprietary and confidential information disclosed to 1CDN under section 8
              (confidentiality) 1CDN shall retain all ownership rights to the deliverables.
            </li>
            <li><b>Proprietary rights</b> - The services, the documentation and the data insights are the
              proprietary information of 1CDN. 1CDN grants subscriber a worldwide, non-exclusive,
              non-transferable, royalty-free license to use the documentation, and the software and
              application programming interfaces made available by 1CDN to subscriber as part of the
              services, solely as necessary to make use of the services during the term of any applicable
              service order. Subject to the limited rights expressly granted in the agreement, 1CDN
              and 1CDN’s licensors reserve all right, title, and interest in and to the services, the
              documentation, and the data insights, including all related intellectual property rights. No
              rights are granted to subscriber except as expressly set forth in the agreement. No rights
              are granted to 1CDN except as expressly set forth in the agreement.
            </li>
          </ul>
          <h2>GOVERNMENT RIGHTS</h2>
          <ul>
            <li>The services may include access to software. In such case, such software is commercial
              computer software. Accordingly, if subscriber is an agency of, or contractor to, the us
              government, it receives only those rights with respect to such software as are granted to
              all other end users under license. If subscriber is a government agency that has a need for
              rights not granted under the agreement, it must negotiate with 1CDN to determine if
              there are acceptable terms for granting those rights, and mutually acceptable written terms
              specifically granting those rights must be included in any applicable agreement.
            </li>
          </ul>
          <h2>ANTI-CORRUPTION</h2>
          <ul>
            <li>Neither party has received or been offered any bribe, kickback, illegal or improper payment,
              gift, or thing of value from any of the personnel or agents of the other party in connection
              with the agreement, other than reasonable gifts and entertainment provided in the ordinary
              course of business. If subscriber becomes aware of any violation of the above restriction,
              subscriber will promptly notify 1CDN’s administrator at contact@1cdn.app.
            </li>
          </ul>
          <h2>LIMITATION OF LIABILITY</h2>
          <ul>
            <li>A party's aggregate liability arising out of or related to the agreement will not exceed the
              amount paid by subscriber hereunder in the 12 months preceding the first incident out of
              which the liability arose. The above limitations will apply whether an action is in contract
              or tort and regardless of the theory of liability. However, the above limitations will not
              limit subscriber’s payment obligations under section 7 (fees and payment) or either party’s
              indemnification obligations under section 16 (indemnification).
            </li>
          </ul>
          <h2>EXCLUSION OF CONSEQUENTIAL AND RELATED DAMAGES</h2>
          <ul>
            <li>In no event will a party have any liability to any other party for any lost profits, lost
              opportunities, or indirect, special, incidental, consequential, cover, business
              interruption, or punitive damages, whether an action is in contract or tort and regardless
              of the theory of liability, even if a party has been advised of the possibility of these
              types of damages. The foregoing disclaimer will not apply to the extent prohibited by law.
            </li>
          </ul>
          <h2>WARRANTIES</h2>
          <ul>
            <li><b>Services warranty</b> - In addition to its obligations under the agreement, 1CDN
              warrants that during the term of each service order that: (a) the services will perform
              materially as set forth in the documentation; (b) the features, functionality and
              performance of the services will not be materially decreased; (c) the overall effectiveness
              of the security measures will not be decreased; (d) use of the services, including the
              software components, in accordance with the documentation, will not impose any additional
              obligations on subscriber to grant any rights to its intellectual property or to disclose or
              make any of its own proprietary technology available to any third party; and (e)
              subscriber’s obligations will not be materially increased as a result of an update to the
              documentation. Without limiting 1CDN’s obligations pursuant to section 3 (1CDN’s
              obligations), subscriber’s exclusive remedies for a breach of a warranty in this section
              15.1 (services warranty) shall be (i) to the extent applicable, allow 1CDN to repair or
              replace a non-conforming software component or (ii) to exercise the express rights described
              in sections 5.2 (termination for cause) and 7.5 (refund or payment upon termination), and to
              the extent applicable, to claim the credits set forth applicable.
            </li>
            <li><b>Support and professional services warranty</b> - The support and professional services
              specified in the applicable service order will be performed in a professional and
              workmanlike manner, in accordance with generally accepted industry standards. Subscriber’s
              exclusive remedies for breach of the warranty in this section 15.2 shall be either (a)
              re-performance of the support or professional services by 1CDN; (b) to claim the credits
              set forth applicable; or (c) to exercise the express rights described in sections 5.2
              (termination for cause) and 5.5 (refund or payment upon termination).
            </li>
            <li><b>Disclaimers</b> - Except as expressly provided in this section 15, neither party makes
              any warranty or guaranty of any kind, whether express, implied, statutory, or otherwise, and
              each party specifically disclaims all warranties, whether implied, express, or statutory,
              including any implied warranty of title, merchantability, fitness for a particular purpose,
              or non-infringement, to the maximum extent permitted by applicable law.
            </li>
          </ul>
          <h2>INDEMNIFICATION</h2>
          <ul>
            <li><b>Indemnification</b> - 1CDN will indemnify and defend subscriber against any and all
              third party claims, demands, suits or proceedings (each a “claim against subscriber”) and
              all related judgments, liabilities, awards, damages, costs, including reasonable attorneys’
              fees and expenses, arising out of or in connection with, or alleging the services or use of
              the services by subscriber in accordance with the agreement infringe or misappropriate the
              third party’s intellectual property rights, provided subscriber (a) promptly gives 1CDN
              written notice of the claim against subscriber; (b) gives 1CDN sole control of the
              defense and settlement of the claim against subscriber (except that 1CDN may not settle
              any claim against subscriber unless it unconditionally releases subscriber of all liability
              related to the claim against subscriber); and (c) gives 1CDN all reasonable assistance,
              at 1CDN’s expense. If 1CDN receives information about an infringement or
              misappropriation claim related to the services, 1CDN may in its discretion and at no
              cost to subscriber (x) modify the service so that it no longer infringes or misappropriates,
              without breaching 1CDN’s obligations under section 3 (1CDN’s obligations); (y)
              obtain a license for subscriber’s continued use of that service in accordance with the
              agreement; or (z) terminate subscriber’s subscriptions for that service upon 30 days’
              written notice and refund subscriber any prepaid fees covering the remainder of the term of
              the terminated subscriptions. The above defense and indemnification obligations do not apply
              to a claim against subscriber that result, in whole or in part, from subscriber data, a
              non-1CDN service, the use or combination of the services with hardware, software, data,
              or processes not provided or specified by 1CDN, if the services or their use would not
              infringe third-party rights absent such combination, any intellectual property claim(s)
              known to subscriber prior to its subscription to the services, or subscriber’s breach of the
              agreement.
            </li>
            <li><b>Indemnification by subscriber</b> - Subscriber will indemnify and defend 1CDN against
              any and all third party claims, demands, suits or proceedings (each a “claim against
              1CDN”) and all related judgments, liabilities, awards, damages, costs, including
              reasonable attorneys’ fees and expenses, arising out of or in connection with (i) subscriber
              data, or (ii) subscriber’s use of the services in breach of the agreement, provided 1CDN
              (a) promptly gives subscriber written notice of the claim against 1CDN; (b) gives
              subscriber sole control of the defense and settlement of the claim against 1CDN (except
              that subscriber may not settle any claim against 1CDN unless it unconditionally releases
              1CDN of all liability related to the claim against 1CDN); and (c) give subscriber
              all reasonable assistance, at subscriber’s expense. The above defense and indemnification
              obligations do not apply to a claim against 1CDN that result, in whole or in part, from
              the services or 1CDN’s breach of the agreement. Except with respect to a dispute between
              subscriber and 1CDN, subscriber will reimburse 1CDN for all costs and reasonable
              attorneys’ fees for responding to third party or governmental requests for information
              arising out of or in connection with subscriber data or subscriber’s use of the services.
            </li>
            <li><b>Additional indemnified parties</b> - For purposes of this section 16, (a) a claim against
              1CDN shall include a claim against 1CDN, 1CDN’s affiliates, and 1CDN’s or
              its affiliates’ officers, directors, and employees and (b) a claim against subscriber shall
              include a third-party claim against subscriber, subscriber’s affiliates, and subscriber’s or
              its affiliates’ officers, directors, and employees; provided no indemnified party shall be
              entitled to any form of equitable or implied indemnification at any time.
            </li>
            <li><b>Exclusive remedy</b> - This section 16 states the indemnifying party’s sole liability to,
              and the indemnified party’s exclusive remedy against, the other party for any type of claim
              described in this section 16.
            </li>
          </ul>
          <h2>ASSIGNMENT</h2>
          <ul>
            <li>Either party may assign the agreement in its entirety, without the other party’s consent (a)
              to its affiliate or (b) in connection with a merger, acquisition, corporate reorganization,
              or sale of all or substantially all of its assets, unless the subscriber is acquired by,
              sells substantially all of its assets to, or undergoes a change of control in favor of, a
              direct competitor of 1CDN, in which case 1CDN may, but is not required to, terminate
              the agreement upon written notice, and in the event of such a termination, 1CDN will
              refund to subscriber any prepaid fees covering the remainder of the initial term of all
              service orders.
            </li>
            <li>Except as permitted in section 17.1, neither party may assign any of its rights or
              obligations under the agreement, whether by operation of law or otherwise, without the other
              party’s prior written consent (not to be unreasonably withheld).
            </li>
            <li>The agreement will bind and inure to the benefit of the parties, their respective
              successors, and permitted assigns.
            </li>
          </ul>
          <h2>MANNER OF GIVING NOTICE</h2>
          <ul>
            <li>Except as otherwise specified in the agreement, all notices, permissions and approvals shall
              be in writing and shall be deemed to have been given upon (a) personal delivery; (b) the
              second business day after mailing; or (c) the first business day after sending by email
              (provided email shall not be sufficient for notices of an indemnifiable claim).
              Billing-related notices to subscriber shall be addressed to the relevant billing contact
              designated by subscriber. All other notices to subscriber shall be addressed to the relevant
              services system administrator or other person designated by subscriber in writing, or, if
              applicable, as set forth on the cover page to the agreement. Notices to 1CDN should be
              addressed to contact@1cdn.app or 1CDN.
            </li>
          </ul>
          <h2>RELATIONSHIP OF THE PARTIES</h2>
          <ul>
            <li>The parties are independent contractors. The agreement does not create a partnership,
              franchise, joint venture, agency, fiduciary, or employment relationship between the parties.
            </li>
          </ul>
          <h2>THIRD-PARTY BENEFICIARIES</h2>
          <ul>
            <li>Subscriber acknowledges that 1CDN’s licensors and technology providers have required
              1CDN to agree to certain provisions with subscriber, including sections 4 (subscriber’s
              obligations), 9 (licenses and proprietary rights), 13 (limitation on liability), 14
              (exclusion of consequential and related damages) and 16 (indemnification).
            </li>
          </ul>
          <h2>AUTHORIZED RESELLER</h2>
          <ul>
            <li>If subscriber has validly purchased services through a reseller authorized by 1CDN (an
              “authorized reseller”) under an ordering document that binds the subscriber to the terms of
              this agreement (a “reseller order”), then 1CDN will make the validly purchased services
              specified in the reseller order available to subscriber. 1CDN may share information
              regarding subscriber with the authorized reseller when necessary for providing the services
              or as needed for the authorized reseller to perform services for the benefit of subscriber.
              A reseller order shall be a service order for purposes of this agreement, provided that, (a)
              sections 7.1, 7.2 and 7.6 of this agreement shall not apply; (b) 1CDN will seek payment
              for all fees associated with subscriber’s use of the services from the authorized reseller,
              (c) any refunds or credits will be issued to the authorized reseller; (d) if any amount
              owing by an authorized reseller for subscriber’s use of the services is overdue, 1CDN
              may, without limiting any rights and remedies, suspend the provision of services to
              subscriber until the overdue amounts are paid in full; (e) amounts paid by subscriber to the
              authorized reseller for services subscribed for by subscriber shall be deemed to be payments
              made by subscriber hereunder for purposes of section 13 (limitation of liability); and (f) a
              reseller order cannot modify or take precedence over the data processing terms, the
              agreement, or the documentation.
            </li>
          </ul>
          <h2>ENTIRE AGREEMENT</h2>
          <ul>
            <li>The agreement supersedes all prior and contemporaneous agreements, proposals, or
              representations, written or oral, concerning its subject matter. In the event of any
              conflict or inconsistency among the following, the order of precedence shall be: (i) the
              data processing terms, (ii) the applicable service order (other than a reseller order),
              (iii) the agreement and (v) the documentation. 1CDN and subscriber each represent that
              it has validly entered into the agreement and has the legal power to do so. Any term or
              condition stated in a subscriber online supplier portal click-through, purchase order or
              other subscriber order documents (excluding service orders) is void. No modification,
              amendment, or waiver of any provision of the agreement will be effective unless it exists in
              writing and is signed by the party against whom the modification, amendment, or waiver is to
              be asserted. No failure or delay by either party in exercising any right under the agreement
              will constitute a waiver of that right. If any provision of the agreement is held by a court
              of competent jurisdiction to be contrary to law, the provision will be deemed null and void,
              and the remaining provisions of the agreement will remain in effect.
            </li>
          </ul>
          <br />
          <h5 className='mb-5'>Feel free to use our services.</h5>
        </Container>
      </section>
      <HomeLayoutFooter />
    </React.Fragment>
  )
}

export default Terms