import { Dispatch, SetStateAction, Suspense, useState } from "react";
import { Routes, Route, Outlet, Navigate, useNavigate } from "react-router-dom";
import { useMutation } from 'react-query'
import { ReactQueryDevtools } from 'react-query/devtools'
import { ToastContainer, toast } from 'react-toastify';
import { ContentContext, contents } from './components/content-context'
import { TourProvider } from '@reactour/tour'
import Swal from "sweetalert2";
import { AxiosError } from "axios";
import HttpService from "./services/http";
import useAuth from "./hooks/useAuth";
import useUi from "./hooks/useUi";

import ErrorBoundary from "./components/public/ErrorBoundary";
import ProtectedRoute from "./components/public/ProtectedRoute";
import LoadingPage from "./components/public/LoadingPage";
import Login from "./routes/auth/Login";
import Register from "./routes/auth/Register";
import Home from "./routes/Home";
import ForgotPassword from "./routes/auth/ForgotPassword";
import ResetPassword from "./routes/auth/ResetPassword";
import LevelUpPassword from "./routes/auth/LevelUpPassword";
import RequireAuth from "./components/public/RequireAuth";
import Dashboard from "./routes/Dashboard";
import Layout from "./components/pages/dashboard/Layout";
import Logout from "./routes/auth/Logout";
import Profile from "./components/pages/dashboard/Profile";
import Contact from "./routes/Contact";
import Plans from "./routes/Plans";
import HomeLayout from "./components/pages/home/HomeLayout";
import AuthLayout from "./components/pages/auth/AuthLayout";
import NotFound from "./routes/NotFound";
import ServerError from "./routes/ServerError";
import FilesList from "./components/pages/dashboard/FilesList";
import Upload from "./components/pages/dashboard/Upload";
import ErrorPagesLayout from "./components/pages/error/ErrorPagesLayout";
import DevTools from "./routes/DevTools";
import AskQuestionMessage from "./components/pages/dashboard/messages/AskQuestionMessage";
import Billing from "./components/pages/dashboard/Billing";
import ScrollToTop from "./components/public/ScrollToTop";
import Terms from "./components/pages/policies/Terms";
import NotPermittedFile from "./routes/NotPermittedFile";
import Maintenance from "./routes/Maintenance";

import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap-icons/font/bootstrap-icons.css';
import 'react-toastify/dist/ReactToastify.css';
import './App.css';
import './AppResponsive.css'

const steps = [
  {
    selector: '[data-tour="step-1"]',
    content: (
      <p className="fs-5 fw-bold">
        Welcome to 1CDN! let us guide you through your first visit.
      </p>
    )
  },
  {
    selector: '[data-tour="step-2"]',
    content: (
      <p className="fs-5 fw-bold">
        This is where you can start uploading your files
      </p>
    )
  },
  {
    selector: '[data-tour="step-3"]',
    content: (
      <p className="fs-5 fw-bold">
        You can drag and drop your files here to upload.
      </p>
    )
  },
  {
    selector: '[data-tour="step-4"]',
    content: (
      <p className="fs-5 fw-bold">
        Here you can check your uploaded files.
      </p>
    )
  },
  {
    selector: '[data-tour="step-5"]',
    content: (
      <p className="fs-5 fw-bold">
        You can filter your files by format, size, etc., as well as delete some of them if you wish.
      </p>
    )
  },
]

function App() {
  const redirect = useNavigate();

  const { customer_identification } = useAuth()
  const { setUiItem } = useUi()

  const [textContents] = useState(contents)
  const [step, setStep] = useState(0);

  const setCurrentStep = (step: number) => {
    switch (step) {
      case 0:
        redirect("/control-panel", { replace: true });
        break;
      case 2:
        redirect("/control-panel/upload-center", { replace: true });
        break;
      case 4:
        redirect("/control-panel/files", { replace: true });
        break;
      default:
        break;
    }

    setStep(step);
  };

  const UpdatePreferencesMutation = useMutation<boolean, AxiosError<any, any>, any>((showTour) => HttpService.patch(`/${customer_identification}/profile/preferences`, {
    _cdn_tour: showTour
  }), {
    onError: (err) => {
      toast.error(err.response?.data?.message || err.message)
    }
  })

  return (
    <ErrorBoundary>
      <div className="App">
        <ContentContext.Provider value={textContents}>
          <Suspense fallback={<LoadingPage />}>
            <ScrollToTop>
              <Routes>
                <Route element={<AuthLayout><Outlet /></AuthLayout>}>
                  <Route path="login" element={<Login />} />
                  <Route path="register" element={<Register />} />
                  <Route path="forgot-password" element={<ForgotPassword />} />
                  <Route path="reset-password" element={<ResetPassword />} />
                  <Route path="level-up-password" element={<LevelUpPassword />} />
                  <Route path="logout" element={<Logout />} />
                </Route>

                <Route path="/" element={<HomeLayout><Outlet /></HomeLayout>}>
                  <Route index element={<Home />} />
                  <Route path="contact" element={<Contact />} />
                  <Route path="plans" element={<Plans />} />
                </Route>

                <Route
                  path="control-panel"
                  element={
                    <RequireAuth>
                      <TourProvider
                        steps={steps}
                        currentStep={step}
                        setCurrentStep={setCurrentStep as Dispatch<SetStateAction<number>>}
                        showDots={false}
                        scrollSmooth={true}
                        disableInteraction={true}
                        onClickMask={() => { }}
                        onClickClose={() => {
                          if (typeof window !== 'undefined') {
                            setStep(-1)

                            Swal.fire({
                              title: 'Show tour on next visit?',
                              icon: 'info',
                              showCancelButton: true,
                              confirmButtonText: 'Yes, show me',
                              cancelButtonText: 'No, do not show again!',
                              focusCancel: true,
                              confirmButtonColor: '#57cc99',
                              allowOutsideClick: false
                            }).then((result) => {
                              if (result.isConfirmed) {
                                UpdatePreferencesMutation.mutate(true, {
                                  onSuccess: () => {
                                    setUiItem('tourFlag', true)
                                    redirect('/control-panel')
                                  }
                                })
                              } else if (result.isDenied || result.isDismissed) {
                                UpdatePreferencesMutation.mutate(false, {
                                  onSuccess: () => {
                                    setUiItem('tourFlag', false)
                                    redirect('/control-panel')
                                  }
                                })
                              }
                            })
                          }
                        }}
                      >
                        <Layout setStep={setStep}>
                          <Outlet />
                        </Layout>
                      </TourProvider>
                    </RequireAuth>
                  }
                >
                  <Route index element={<Dashboard />} />
                  <Route path="profile" element={<Profile />} />
                  <Route path="files" element={<FilesList />} />
                  <Route path="upload-center" element={<Upload />} />
                  <Route path="dev-tools" element={<ProtectedRoute permission="open_api">
                    <DevTools />
                  </ProtectedRoute>} />
                  <Route path="messages" element={<AskQuestionMessage />} />
                  <Route path="billing" element={<Billing />} />
                </Route>

                <Route path="/tos" element={<Terms />} />

                <Route path="/not-permitted" element={<NotPermittedFile />} />

                <Route element={<ErrorPagesLayout>
                  <Outlet />
                </ErrorPagesLayout>}>
                  <Route path="/server-error" element={<ServerError />} />
                  <Route path="/not-found" element={<NotFound />} />
                  <Route path="/maintenance" element={<Maintenance />} />
                </Route>

                <Route path="*" element={<Navigate to={'/not-found'} />} />

              </Routes>
            </ScrollToTop>
            <ToastContainer />
          </Suspense>
        </ContentContext.Provider>
        <ReactQueryDevtools initialIsOpen={false} />
      </div>
    </ErrorBoundary>
  );
}

export default App;
