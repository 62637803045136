import {useState, useRef} from "react";
import {Col, Container, Form, Row, Spinner, Toast} from "react-bootstrap"
import {useForm} from "react-hook-form"
import {joiResolver} from '@hookform/resolvers/joi'
import * as Joi from 'joi';
import {useMutation} from "react-query";
import HttpService from "../services/http";
import {toast} from "react-toastify";
import ReCAPTCHA from 'react-google-recaptcha';
import {AxiosError} from "axios";
import {Helmet} from "react-helmet-async";

const schema = Joi.object({
    email: Joi.string().required().label("Email"),
    name: Joi.string().min(2).max(200).required().label("Name"),
    message: Joi.string().min(10).max(3000).required().label("Message"),
}).required()

const Contact = () => {
    const {register, handleSubmit, formState: {errors}, reset} = useForm({
        resolver: joiResolver(schema)
    })

    const [show, setShow] = useState(false);
    const [error, setError] = useState<any>();
    const [counter, setCounter] = useState(0);
    const [isRecaptchaVerified, setIsRecaptchaVerified] = useState(false);
    const [isRecaptchaHidden, setIsRecaptchaHidden] = useState(true);
    const captchaRef = useRef<any>(null);

    const ContactUsMutation = useMutation<any, AxiosError<any, any>, any>((data) => HttpService.post(`/ui/contact-us`, data), {
        onError: (err) => {
            toast.error(err.response?.data.message || err.message);
            setError(err.response?.data.error);
            setShow(false);
        },
        onSuccess: (res: any) => {
            setShow(true);
            setCounter(counter + 1);
            reset();
            setError(null)
        }
    })

    const handleChangeInputValue = () => {
        setIsRecaptchaHidden(false)
    }

    const onRecaptchaChange = () => {
        if (captchaRef.current.getValue()) {
            setIsRecaptchaVerified(true);
            setError(null);
        } else {
            setIsRecaptchaVerified(false);
        }
    }

    const onSubmit = async (values: any) => {
        if (!isRecaptchaVerified) {
            setShow(true);
            return
        } else {
            if (counter <= 0) {
                ContactUsMutation.mutate(values)
            } else {
                setError({
                    message: "You have already sent a message."
                })
            }
        }
    }

    return (
        <>
            <Helmet>
                <title>Contact us</title>
                <meta name="description" content="Contact us"/>
                <link rel="canonical" href={`${process.env.REACT_APP_PAYMENT_SERVICE_REDIRECT_PAGE}/contact`}/>
            </Helmet>

            <div className="plans-page cs-p">
                <Container fluid='lg'>
                    <div className="title">
                        <Row className="mb-5 align-items-end">
                            <Col md={6} xs={12} className="mb-sm-0 mb-3">
                                <h1 className="mb-0">
                                    Contact <br/> Us
                                </h1>
                            </Col>
                            <Col md={6} xs={12}>
                                <p className="mb-0">

                                    Our mission is to make sure you achieve your goals, so if there's a question you
                                    haven't found an answer to, send us your message, and we'll get back to you as soon
                                    as possible.</p>
                            </Col>
                        </Row>
                    </div>
                    <Form onSubmit={handleSubmit(onSubmit)}>
                        <Row>
                            <Col sm={6} xs={12}>
                                <Form.Group className="mb-3">
                                    <Form.Control type="email" isInvalid={!!errors.email || error?.email}
                                                  placeholder="Enter your email" {...register("email")}
                                                  onChange={handleChangeInputValue}/>
                                    <Form.Control.Feedback type="invalid">
                                        {errors.email?.message || error?.email}
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                            <Col sm={6} xs={12}>
                                <Form.Group className="mb-3">
                                    <Form.Control type="text" isInvalid={!!errors.name || error?.name}
                                                  placeholder="Enter your name" {...register("name")}
                                                  onChange={handleChangeInputValue}/>
                                    <Form.Control.Feedback type="invalid">
                                        {errors.name?.message || error?.name}
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                        </Row>

                        <Form.Group className="mb-3">
                            <Form.Control style={{resize: 'none'}} isInvalid={!!errors.message || error?.message}
                                          placeholder="Your message..." as='textarea' rows={8} {...register("message")}
                                          onChange={handleChangeInputValue}/>
                            <Form.Control.Feedback type="invalid">
                                {errors.message?.message || error?.message}
                            </Form.Control.Feedback>
                        </Form.Group>

                        <div
                            className="d-flex align-items-center justify-content-end p-3"
                        >
                            <ReCAPTCHA
                                sitekey="6LefdPMhAAAAAEHbWrzzPHhAOGX6NpgbCmzNYAR4"
                                onChange={onRecaptchaChange}
                                hidden={isRecaptchaHidden}
                                ref={captchaRef}
                            />
                        </div>
                        <div className={`d-flex align-items-center justify-content-end`}>
                            <button type="submit" className='btn btn-dark'
                                    disabled={ContactUsMutation.isLoading || !isRecaptchaVerified}>
                                {ContactUsMutation.isLoading ? <Spinner animation='grow' size='sm'/> : "Send"}
                            </button>
                        </div>

                    </Form>

                    <Toast onClose={() => setShow(false)} show={show} delay={3000} autohide
                           style={{position: 'fixed', left: 10, bottom: 10}}>
                        <Toast.Header>
                            <strong className="me-auto text-success">Success</strong>
                        </Toast.Header>
                        <Toast.Body>
                            Thanks for your feedback!
                        </Toast.Body>
                    </Toast>

                </Container>
            </div>
        </>
    )
}

export default Contact