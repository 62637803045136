import { AxiosError, AxiosResponse } from 'axios';
import { useRef, useState } from 'react';
import { Alert, Button, ProgressBar, Spinner } from 'react-bootstrap';
import { Dropzone, FileItem, FileValidated, FullScreenPreview } from "@dropzone-ui/react";
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import useAuth from '../../../hooks/useAuth';
import HttpService from '../../../services/http';
import { Helmet } from 'react-helmet-async';

// const formats = [
//   ".avif",
//   ".gif",
//   ".jpeg",
//   ".jpg",
//   ".json",
//   ".mp3",
//   ".mp4",
//   ".pdf",
//   ".png",
//   ".svg",
//   ".webp",
//   ".css",
//   ".js",
//   ".xml",
//   ".tsx",
//   ".jsx",
//   ".wav",
//   ".avif".toUpperCase(),
//   ".gif".toUpperCase(),
//   ".jpeg".toUpperCase(),
//   ".jpg".toUpperCase(),
//   ".json".toUpperCase(),
//   ".mp3".toUpperCase(),
//   ".mp4".toUpperCase(),
//   ".pdf".toUpperCase(),
//   ".png".toUpperCase(),
//   ".svg".toUpperCase(),
//   ".webp".toUpperCase(),
//   ".css".toUpperCase(),
//   ".js".toUpperCase(),
//   ".xml".toUpperCase(),
//   ".tsx".toUpperCase(),
//   ".jsx".toUpperCase(),
//   ".wav".toUpperCase(),
//   // "image/png",
//   // "image/jpg",
//   // "image/jpeg",
//   // "image/avif",
//   // "image/gif",
//   // "application/json",
//   // "audio/mpeg",
//   // "video/mp4",
//   // "application/pdf",
//   // "image/svg+xml",
//   // "image/webp",
//   // "text/css",
//   // "text/javascript",
//   // "application/xml",
//   // "application/javascript",
//   // "application/octet-stream",
//   // "text/vnd.qt.linguist",
//   // "text/xml",
//   // "audio/wav",
//   // "audio/x-wav"
// ]

// const formatLabels = [
//   "avif",
//   "gif",
//   "jpeg",
//   "jpg",
//   "json",
//   "mp3",
//   "mp4",
//   "pdf",
//   "png",
//   "svg",
//   "webp",
//   "css",
//   "js",
//   "xml",
//   "tsx",
//   "jsx",
//   "wav",
// ]

const Upload = () => {
  const navigate = useNavigate();

  const { customer_identification, user } = useAuth();

  const queryClient = useQueryClient()

  const [showAlert, setShowAlert] = useState(false)
  const [percentageUpload, setPercentageUpload] = useState<number>(0);
  const [files, setFiles] = useState<any[]>([]);
  const [imageSrc, setImageSrc] = useState(undefined);
  const abortControllerRef = useRef<AbortController | null>(null)
  const [showUploadCancelBtn, setShowUploadCancelBtn] = useState(false)
  const [formats, setformats] = useState<string[]>([])
  const [formatLabels, setFormatLabels] = useState<string[]>([])

  const acceptableFormatsQuery = useQuery('acceptable-format', () => HttpService.get('/ui/acceptable-format'), {
    onSuccess: (data: AxiosResponse<any, any>) => {
      setformats([...data.data.element, data.data.element.map((item: string) => item.toUpperCase())])
      setFormatLabels([...data.data.element.map((item: string) => item.slice(1))])
    }
  });

  let formatsContent;

  if (acceptableFormatsQuery.isLoading) formatsContent = <Spinner animation='border' size='sm' />
  if (acceptableFormatsQuery.isError) formatsContent = <Button size='sm' variant='secondary' onClick={() => acceptableFormatsQuery.refetch()}>Reload</Button>
  if (acceptableFormatsQuery.isSuccess) formatsContent = <p className="mb-0" style={{ overflowWrap: 'break-word' }}>{formatLabels.join(', ')}</p>

  const updateFiles = (incomingFiles: FileValidated[]) => {
    let acceptableFiles: any = [];

    incomingFiles.forEach((file) => {
      if (
        (['video/mp4'].includes(file.file.type) && file.file.size < user?.user_permissions?.video_max_limit * 1048576) ||
        (!['video/mp4'].includes(file.file.type) && file.file.size < user?.user_permissions?.media_max_limit * 1048576)
      ) {
        acceptableFiles.push(file)
      } 
      else {
        file.valid = false
        file.errors?.push('File size is not acceptable')
        acceptableFiles.push(file)
      }
    });

    setFiles(acceptableFiles);
  };

  const removeFile = (id: string) => {
    setFiles(files.filter((x) => x.id !== id));
  };

  const handleSee = (imageSource: any) => {
    setImageSrc(imageSource);
  };

  const UploadFileMutation = useMutation<any, AxiosError<any, any>, any>(
    (files) => {
      abortControllerRef.current = new AbortController()

      return HttpService.post(`/${customer_identification}/cdn`, files, {
        auth: HttpService.getToken(),
        headers: {
          "Content-Type": "multipart/form-data",
        },
        signal: abortControllerRef?.current?.signal,
        onUploadProgress: progressEvent => {
          let percentComplete = progressEvent.loaded / progressEvent.total
          percentComplete = percentComplete * 100;
          setPercentageUpload(percentComplete);
        }
      })
    },
    {
      onMutate: () => {
        setShowAlert(false)
        setShowUploadCancelBtn(true)
      },
      onError: (err) => {
        toast.error(err.response?.data?.message || err.message)

        setShowAlert(true)
        setShowUploadCancelBtn(false)
        setPercentageUpload(0)
      }
    }
  )

  const handleResetUploader = () => {
    setPercentageUpload(0);
    setShowAlert(false);
  }

  const cancelUploadProcess = () => {
    abortControllerRef.current?.abort()
    UploadFileMutation.reset()
    setPercentageUpload(0)
  }

  const handleUploadFiles = () => {
    setShowAlert(false);

    let formData = new FormData();
    let okFiles: File[] = [];

    for (let index = 0; index < files.length; index++) {
      const element = files[index];

      if (element.valid) {
        formData.append('files', element.file)
        okFiles.push(element.file)
      }
    }

    // Check total files size limitation based on files and formats and user max video limit
    const videoFiles: File[] = okFiles.filter((file: File) => file.type === 'video/mp4')
    const otherFiles: File[] = okFiles.filter((file: File) => file.type !== 'video/mp4')

    let totalVideoFilesSize = videoFiles.reduce((accumulator, obj) => {
      return accumulator + obj.size
    }, 0);

    let totlaOtherFilesSize = otherFiles.reduce((accumulator, obj) => {
      return accumulator + obj.size
    }, 0);

    const maxLimit = user?.user_permissions?.video_max_limit * 1048576

    if (
      totalVideoFilesSize > maxLimit || totlaOtherFilesSize > maxLimit || (totalVideoFilesSize + totlaOtherFilesSize) > maxLimit
    )
      return toast.error(`Max upload size of all files can not be more than ${Math.round(maxLimit / 1000000)} MB`)

    UploadFileMutation.mutate(formData, {
      onSuccess: (res) => {
        queryClient.invalidateQueries('files')
        queryClient.invalidateQueries('total-file');

        setShowUploadCancelBtn(false)

        navigate('/control-panel/files')

        toast.success(`${res.data.element.uploadedFiles.length} files uploaded successfully`)
      }
    })
  }

  const acceptableFormats = user?.user_permissions?.supported_file_types === 'all' ? formats?.join(',') : user?.user_permissions?.supported_file_types

  return (
    <>
      <Helmet>
        <title>1CDN - Upload center</title>
        <meta name="description" content="1CDN - Upload center" />
        <link rel="canonical" href={`${process.env.REACT_APP_PAYMENT_SERVICE_REDIRECT_PAGE}/control-panel/upload-center`} />
      </Helmet>
      <div className='dl-shadow-box' data-tour="step-3">
        {UploadFileMutation.isError && showAlert && UploadFileMutation.error instanceof AxiosError && <Alert variant="danger" onClose={() => setShowAlert(false)} dismissible>
          <Alert.Heading>{UploadFileMutation.error.response?.data ? UploadFileMutation.error.response?.data?.message : UploadFileMutation.error.message}</Alert.Heading>
          <p>
            {UploadFileMutation.error.response?.data && typeof UploadFileMutation.error.response?.data?.element === 'object' &&
              <ul>
                {UploadFileMutation.error.response?.data?.element && Object.keys(UploadFileMutation.error.response?.data?.element).map((key, index) => (
                  <li key={index}>{key}: {Array.isArray(UploadFileMutation.error.response?.data?.element[key]) ? <ul>
                    {UploadFileMutation.error.response?.data?.element[key].map((item: any) => <li>{JSON.stringify(item)}</li>)}
                  </ul> : typeof UploadFileMutation.error.response?.data?.element[key] === 'object' ? JSON.stringify(UploadFileMutation.error.response?.data?.element[key]) : UploadFileMutation.error.response?.data?.element[key]}</li>
                ))}
              </ul>
            }
          </p>
        </Alert>}

        <ul className="list-unstyled">
          <li>
            <span className="text-black-50">Max allowed video size:</span> <b className='text-warning'>{user?.user_permissions?.video_max_limit}mb</b>
          </li>
          <li>
            <span className="text-black-50">Max allowed other files size (except video files):</span> <b className='text-warning'>{user?.user_permissions?.media_max_limit}mb</b>
          </li>
        </ul>

        <Dropzone
          onChange={updateFiles}
          value={files}
          footer={false}
          accept={acceptableFormats}
          maxFiles={12}
          fakeUploading
          onReset={handleResetUploader}
          onClean={() => setPercentageUpload(0)} style={{ marginTop: '2rem' }}
        >
          {files.map((file) => (
            <FileItem
              {...file}
              onDelete={removeFile}
              onSee={handleSee} key={file.id}
              resultOnTooltip
              preview
              info
              hd />
          ))}

          <FullScreenPreview
            imgSource={imageSrc}
            openImage={imageSrc}
            onClose={() => handleSee(undefined)}
          />
        </Dropzone>

        <Alert variant='success' className='mt-2'>
          <Alert.Heading>Acceptable formats:</Alert.Heading>
          {formatsContent}
        </Alert>

        <ProgressBar variant='success' now={percentageUpload} label={`${percentageUpload.toFixed(0)}%`} className='my-3' />

        <Button variant='success' onClick={handleUploadFiles} disabled={files.length <= 0 || UploadFileMutation.isLoading || files.some((file: any) => file.valid !== true)}>
          {UploadFileMutation.isLoading ? <Spinner size='sm' animation='grow' /> : "Upload"}
        </Button>

        {showUploadCancelBtn && <Button variant='warning' onClick={cancelUploadProcess} className="ms-2">Cancel upload</Button>}

      </div>
    </>
  )
}

export default Upload