import { useEffect, useState } from 'react'
import { toast } from 'react-toastify';

import { ReactComponent as CodeIcon } from '../components/pages/dashboard/icons/code.svg'
import { ReactComponent as PortraitIcon } from '../components/pages/dashboard/icons/fi-rr-mode-portrait.svg'
import { ReactComponent as LockIcon } from '../components/pages/dashboard/icons/fi-rr-lock-dark.svg'
import { ReactComponent as UserIcon } from '../components/pages/dashboard/icons/fi-rr-user.svg'
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { Helmet } from 'react-helmet-async';
import useAppLocalStorage from '../hooks/useAppLocalStorage';

export const renderHiddenToken = (token: string) => {
  let hiddenToken = token;
  let toReplace = hiddenToken.slice(1, -1);

  toReplace = toReplace.replace(/[a-z0-9]/gi, '*');

  return token.charAt(0) + toReplace + token.charAt(token.length - 1)
}

const DevTools = () => {
  const localStorage = useAppLocalStorage()

  const [swaggerInfos, setSwaggerInfos] = useState<{
    username: string;
    password: string;
    user_identification: string
  }>()
  const [showToken, setShowToken] = useState(false);

  useEffect(() => {
    let clientCredentials = localStorage.getItem('_cdn_service');

    if (clientCredentials) {
      const { user_email, accessToken, userIdentification } = JSON.parse(clientCredentials);
      setSwaggerInfos({
        username: user_email,
        password: accessToken,
        user_identification: userIdentification,
      })
    }

  }, [localStorage])

  const handleCopyCredentials = (item: 'username' | 'password' | 'user_identification') => {
    if (swaggerInfos) {
      window.navigator.clipboard.writeText(swaggerInfos[item]);

      toast.info(`${item} copied`)
    }
  }

  return (
    <>
      <Helmet>
        <title>Settings</title>
        <meta name="description" content="Settings" />
        <link rel="canonical" href={`${process.env.REACT_APP_PAYMENT_SERVICE_REDIRECT_PAGE}/control-panel/dev-tools`} />
      </Helmet>
      <div className='dl-shadow-box'>
        <div className="d-flex align-items-center justify-content-between mb-4 flex-wrap">
          <div className='mb-xl-0 mb-3'>
            <h5 className='mb-2 fw-bold'>Swagger</h5>
            <small className="text-black-50 mb-0 ">
              You will need these pieces of information in order to be able to communicate with the swagger interface.
            </small>
          </div>
          <div className='d-flex mt-md-2'>
            <a href={process.env.REACT_APP_CDN_SWAGGER} target={'_blank'} rel='noreferrer' className='btn btn-success btn-sm d-flex align-items-center me-2'>
              <CodeIcon className='me-2' width={20} />
              Swagger
            </a>
            {/* <Button variant="outline-success ms-auto" size='sm' onClick={handleCopyCredentials}>
                        Copy credentials
                    </Button> */}
          </div>
        </div>
        <ul className="list-unstyled mb-0 p-0 swagger-infos-list">
          <li>
            <div className="title">
              <UserIcon className='me-2 d-sm-inline-block d-none' />
              <h5 className='noselect'>Username:</h5>
            </div>
            <div className="content">
              <OverlayTrigger
                placement={'top'}
                overlay={
                  <Tooltip>
                    Double click to copy
                  </Tooltip>
                }
              >
                <b className="ms-sm-2" onDoubleClick={() => handleCopyCredentials('username')}>{swaggerInfos?.username}</b>
              </OverlayTrigger>
            </div>
          </li>
          <li>
            <div className="title">
              <LockIcon className='me-2 d-sm-inline-block d-none' />
              <h5 className='noselect'>Password:</h5>
            </div>
            <div className="content">
              <OverlayTrigger
                placement={'top'}
                overlay={
                  <Tooltip>
                    Double click to copy
                  </Tooltip>
                }
              >
                <b className="ms-sm-2" onMouseDown={() => setShowToken(true)} onMouseUp={() => setShowToken(false)} style={{ cursor: 'grab' }} onDoubleClick={() => handleCopyCredentials('password')}>
                  {swaggerInfos && (
                    <>
                      {showToken ? `${swaggerInfos.password}` :
                        renderHiddenToken(swaggerInfos?.password as string)
                      }
                    </>
                  )}
                </b>
              </OverlayTrigger>
            </div>
          </li>
          <li className='d-flex mb-0'>
            <div className="title">
              <PortraitIcon className='me-2 d-sm-inline-block d-none' />
              <h5 className='noselect'>User id:</h5>
            </div>
            <div className="content">
              <OverlayTrigger
                placement={'top'}
                overlay={
                  <Tooltip>
                    Double click to copy
                  </Tooltip>
                }
              >
                <b className="ms-sm-2" onDoubleClick={() => handleCopyCredentials('user_identification')}>{swaggerInfos?.user_identification}</b>
              </OverlayTrigger>
            </div>
          </li>
        </ul>
      </div>
    </>
  )
}

export default DevTools