import { AxiosError } from 'axios'
import React from 'react'
import { Button, Spinner } from 'react-bootstrap'
import { useQuery } from 'react-query'
import { Link } from 'react-router-dom'
import { toast } from 'react-toastify'
import useAuth from '../../../hooks/useAuth'
import HttpService from '../../../services/http'
import { handleCopyCdnPath } from './FilesList'

export const renderFileThumbnail = (fileExtension: string) => {
  switch (fileExtension) {
    case "avif":
      return <img loading="lazy" src={`/images/files/${'avif'.toUpperCase()}.svg`} alt=".avif file" width={40} height={40} />
    case "gif":
      return <img loading="lazy" src={`/images/files/${'gif'.toUpperCase()}.svg`} alt=".gif file" width={40} height={40} />
    case "jpeg":
      return <img loading="lazy" src={`/images/files/${'jpeg'.toUpperCase()}.svg`} alt=".jpeg file" width={40} height={40} />
    case "jpg":
      return <img loading="lazy" src={`/images/files/${'jpg'.toUpperCase()}.svg`} alt=".jpg file" width={40} height={40} />
    case "json":
      return <img loading="lazy" src={`/images/files/${'json'.toUpperCase()}.svg`} alt=".json file" width={40} height={40} />
    case "mp3":
      return <img loading="lazy" src={`/images/files/${'mp3'.toUpperCase()}.svg`} alt=".mp3 file" width={40} height={40} />
    case "mp4":
      return <img loading="lazy" src={`/images/files/${'mp4'.toUpperCase()}.svg`} alt=".mp4 file" width={40} height={40} />
    case "pdf":
      return <img loading="lazy" src={`/images/files/${'pdf'.toUpperCase()}.svg`} alt=".pdf file" width={40} height={40} />
    case "png":
      return <img loading="lazy" src={`/images/files/${'png'.toUpperCase()}.svg`} alt=".png file" width={40} height={40} />
    case "svg":
      return <img loading="lazy" src={`/images/files/${'svg'.toUpperCase()}.svg`} alt=".svg file" width={40} height={40} />
    case "webp":
      return <img loading="lazy" src={`/images/files/${'webp'.toUpperCase()}.svg`} alt=".webp file" width={40} height={40} />
    case "css":
      return <img loading="lazy" src={`/images/files/${'css'.toUpperCase()}.svg`} alt=".css file" width={40} height={40} />
    case "js":
      return <img loading="lazy" src={`/images/files/${'js'.toUpperCase()}.svg`} alt=".js file" width={40} height={40} />
    case "xml":
      return <img loading="lazy" src={`/images/files/${'xml'.toUpperCase()}.svg`} alt=".xml file" width={40} height={40} />
    case "tsx":
      return <img loading="lazy" src={`/images/files/${'tsx'.toUpperCase()}.svg`} alt=".tsx file" width={40} height={40} />
    case "jsx":
      return <img loading="lazy" src={`/images/files/${'jsx'.toUpperCase()}.svg`} alt=".jsx file" width={40} height={40} />
    case "ts":
      return <img loading="lazy" src={`/images/files/${'ts'.toUpperCase()}.svg`} alt=".ts file" width={40} height={40} />
    case "wav":
      return <img loading="lazy" src={`/images/files/${'wav'.toUpperCase()}.svg`} alt=".wa file" width={40} height={40} />
    default:
      return "No preview";
  }

}

const LatestUploadedFiles = () => {
  const { customer_identification } = useAuth();

  let filterQueries = {
    from: "1",
    number: "4",
    extension: "all",
    sortby: "date-desc",
  }

  const { isLoading, isError, isSuccess, data: res, refetch } = useQuery(['files', filterQueries], () => HttpService.get(`/${customer_identification}/cdn?from=${filterQueries.from}&number=${filterQueries.number}&extension=${filterQueries.extension}&sortby=${filterQueries.sortby}`, {
    auth: HttpService.getToken()
  }), {
    onError: (err: AxiosError<any, any>) => {
      toast.error(err.response?.data?.message || err.message)
    }
  })

  const renderFileName = (name: any) => {
    const [, ...rest] = name.split('_');

    let text = rest.join('_')

    if (text.length > 20)
      return <span title={name}>{text.substring(0, 10) + '....' + name.substr(-7)}</span>
    else
      return <span>{text}</span>
  }

  const renderLatestItems = (files: any[] | []) => {
    return <ul className='list-unstyled p-0 m-0 dl-latest-files-list'>
      {files.length > 0 ? files.map((file) => <li key={file.file}>
        {['png', 'jpeg', 'jpg', 'svg', 'gif'].includes(file.extension) ? (
          <img loading="lazy" key={file.file} src={file.cdnPath} alt={file.file} className='img-fluid' width={40} height={40} />
        ) : (
          <React.Fragment key={file.file}>
            {renderFileThumbnail(file.extension)}
          </React.Fragment>
        )}

        <small>{renderFileName(file.file)}</small>

        <div className="btn-area">
          <a href={file.cdnPath} className="btn bg-dl-blue text-white btn-sm rounded-3" target={'_blank'} rel='noreferrer'>
            <span className='d-sm-block d-none'>
              View
            </span>
            <i className='d-sm-none d-block'>
              <i className='bi bi-eye'></i>
            </i>
          </a>
          <Button size='sm' className='rounded-3 btn-success-darker' onClick={() => handleCopyCdnPath(file.cdnPath)}>
            <span className='d-sm-block d-none'>
              Copy path
            </span>
            <i className='d-sm-none d-block'>
              <i className='bi bi-clipboard'></i>
            </i>
          </Button>
        </div>
      </li>) : <div>
        <p>There are no files uploaded.</p>
        <Link to={'upload-center'} className='btn btn-link text-success p-0'>Upload new files</Link>
      </div>
      }
    </ul>
  }

  let content;

  if (isLoading) content = <Spinner animation='grow' />

  if (isError) content = <Button variant='secondary' onClick={() => refetch()}>Reload</Button>
  if (isSuccess) content = <>
    {res?.data.element?.files.length > 0 ? (
      <div className='dl-shadow-box' style={{ minHeight: 312 }}>
        <h4 className='mb-3 fw-bold'>
          Last uploaded files
        </h4>
        {renderLatestItems(res?.data.element?.files)}
      </div >
    ) : null}
  </>

  return (
    <React.Fragment>
      {content}
    </React.Fragment>
  )
}

export default LatestUploadedFiles