/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect, useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
import { useSearchParams, useNavigate } from "react-router-dom";
import useAppLocalStorage from "../hooks/useAppLocalStorage";
import { cdnPlans } from "../routes/Plans";
import useAuth from "../hooks/useAuth";
import { useMutation, useQueryClient } from "react-query";
import { AxiosError } from "axios";
import HttpService from "../services/http";
import { toast } from "react-toastify";

const planNameMapper = (plan: string | null) => {
  switch (plan) {
    case "CDN_PREMIUM_BUSINESS_ANNUAL_WITH_TRIAL":
    case "CDN_PREMIUM_BUSINESS_ANNUAL":
      return "Annual business";
    case "CDN_PREMIUM_BUSINESS_MONTH":
      return "Monthly business";
    case "CDN_FREE_MONTH":
      return "Monthly Free";
    case "CDN_FREE_ANNUAL":
      return "Annual free";
    default:
      return plan;
  }
};

const handleLogPlanAndRedirect = (
  destination: string,
  plan: string,
  localStorage: any
) => {
  localStorage.setItem("_cdn_service_client_plan", plan);

  window.location.href = destination;
};

const PlansModalBody = ({
  user_identification,
  chosenPlan = null,
}: {
  user_identification: string;
  chosenPlan?: string | null;
}) => {
  const localStorage = useAppLocalStorage();
  const [searchParams] = useSearchParams();
  const queryClient = useQueryClient();
  const auth = useAuth();
  const navigate = useNavigate();
  const [prevPlan, setPrevPlan] = useState<string | null>();
  const [isYearly, setIsYearly] = useState(
    chosenPlan && chosenPlan.includes("MONTH")
      ? false
      : prevPlan && prevPlan.includes("MONTH")
      ? false
      : true
  );
  const isNewUser = useAuth();
  useEffect(() => {
    const localStoragePlan = localStorage.getItem("_cdn_service_client_plan");
    if (localStoragePlan) setPrevPlan(localStoragePlan);
;
  }, [localStorage]);

  const createFreeSubscriptionApi = useMutation<any, AxiosError<any, any>, any>(
    (data: any) => HttpService.post(`${user_identification}/create-free-subscription`, data),
    {
      onMutate: () => {

      },
      onError: (err) => {
        toast.error(err.response?.data.message || err.message);
      },
    }
  );

  const LoginUserMutation = useMutation<any, AxiosError<any, any>>(
    (credentials: any) => HttpService.post("/auth/login", credentials),
    {
      onMutate: () => {

      },
      onError: (err) => {
        toast.error(err.response?.data.message || err.message);

        if (
          err.response?.status !== 400 ||
          err.response?.data.code !== "RSUSKO014"
        ) {
          localStorage.removeItem("_cdn_credential");
        }
      },
    }
  );

  const choosePlanHandler = (plan_code: string) => {
    if(plan_code.includes("FREE")) {
      let credentials = localStorage.getItem("_cdn_credential");

      if(credentials) {
        let { user_email, user_password } = JSON.parse(credentials as string);
        const data: any = { user_email, user_password}

        createFreeSubscriptionApi.mutate({ email: user_email }, {
          onSuccess: () => {
            LoginUserMutation.mutate(data, {
              onSuccess: (res) => {
      
                queryClient
                  .fetchQuery("payment-status", () =>
                    HttpService.get(
                      `/auth/${res?.data?.element?.userIdentification}/verify-payment`
                    )
                  )
                  .then((value) => {
                    localStorage.removeItem("_cdn_credential");
                    localStorage.removeItem("_cdn_service_client_plan");
      
                    auth.login({
                      ...res.data.element,
                      user_email: user_email,
                      accessToken: value.data.element.accessToken,
                      rememberMe: false,
                    });
      
                    return navigate(
                      `/control-panel?user_plan=${plan_code}`,
                      { replace: true }
                    );
                  })
                  .catch((err: any) => {
                    localStorage.removeItem("_cdn_credential");
      
                    toast.error(err.response?.data.message || err.message);
                  });
              },
            });
          },
        });
      }
    }else {
      handleLogPlanAndRedirect(
        `${process.env.REACT_APP_PAYMENT_SERVICE}/payment/subscription?template_image_url=${process.env.REACT_APP_PAYMENT_SERVICE_TEMPLATE_IMAGE_URL}&redirect_page=${process.env.REACT_APP_PAYMENT_SERVICE_REDIRECT_PAGE}/login&user_identification=${user_identification}&payment_plan_identification=${plan_code}&language_code=en&entity_identification=${process.env.REACT_APP_CDN_IDENTIFICATION}`,
        plan_code,
        localStorage
      )
    }
  }

  const renderPlanSlides = () => {
    let plans = [];

    if (isYearly)
      plans = cdnPlans.filter((plan: any) => plan.recurrence === "ANNUALLY");
    else plans = cdnPlans.filter((plan: any) => plan.recurrence === "MONTHLY");

    if (chosenPlan && chosenPlan !== "CDN_FREE_ANNUAL")
      return plans
        .filter((p) => p.plan_title !== "Free")
        .map((plan, index) => (
          <div
            className={`planCard my-3 ${
              (chosenPlan && chosenPlan === plan.plan_code) ||
              (prevPlan && prevPlan === plan.plan_code)
                ? "active"
                : ""
            }`}
            key={index}
          >
            <Row>
              <Col md={4} className="mb-md-0 mb-3 cl1">
                <h3>{plan.plan_title}</h3>
                <h1>
                  {plan.amount / 100 > 0 ? (
                    <>
                      {plan.recurrence === "ANNUALLY" ? (
                        <>
                          {(plan.amount / 100 / 12).toLocaleString("en-US", {
                            style: "currency",
                            currency: plan.currency,
                            maximumFractionDigits: 0,
                            minimumFractionDigits: 0,
                          })}
                          <small> / Month </small>
                          <sup>
                            <span
                              style={{
                                color: "#808080",
                                textDecoration: "line-through",
                              }}
                            >
                              <span style={{ color: "#808080" }}>
                                {cdnPlans.find(
                                  (item) =>
                                    item.recurrence === "MONTHLY" &&
                                    item.plan_title === "Premium"
                                )?.amount &&
                                  "$" +
                                    (cdnPlans.find(
                                      (item) =>
                                        item.recurrence === "MONTHLY" &&
                                        item.plan_title === "Premium"
                                    )?.amount as number) /
                                      100}
                              </span>
                            </span>
                          </sup>
                        </>
                      ) : (
                        <>
                          {(plan.amount / 100).toLocaleString("en-US", {
                            style: "currency",
                            currency: plan.currency,
                            maximumFractionDigits: 0,
                          })}
                          <small> / Month </small>
                        </>
                      )}
                    </>
                  ) : null}

                  {/* {(plan.amount / 100).toLocaleString('en-US', {
                style: 'currency',
                currency: plan.currency,
                maximumFractionDigits: 0
              }) + " " + plan.currency} */}
                </h1>
                {plan.recurrence === "ANNUALLY" && plan.plan_title !== "Free" && (
                  <a className="btn btn-dark rounded-pill mt-3">
                    Annual one-time charge of <b>${plan.amount / 100}</b>
                  </a>
                )}
                {plan.recurrence === "MONTHLY" && plan.plan_title !== "Free" && (
                  <a className="btn btn-dark rounded-pill mt-3">
                    Pay annually and save upt to <b>20%</b>
                  </a>
                )}
              </Col>
              <Col md={5} className="mb-md-0 mb-3">
                <ul className="list-unstyled mb-0 p-0 h-100 d-flex flex-column featuresList">
                  {plan.features.map((fe: string, index: number) => (
                    <li key={index}>
                      <svg
                        width="24"
                        height="17"
                        viewBox="0 0 24 17"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M22.3186 0.609052L8.49963 14.2145C8.40672 14.3063 8.29631 14.3792 8.17473 14.4289C8.05314 14.4786 7.92279 14.5042 7.79113 14.5042C7.65948 14.5042 7.52912 14.4786 7.40754 14.4289C7.28596 14.3792 7.17554 14.3063 7.08263 14.2145L1.73863 8.94776C1.64572 8.85592 1.53531 8.78304 1.41373 8.73332C1.29214 8.68359 1.16179 8.658 1.03013 8.658C0.898479 8.658 0.768121 8.68359 0.646539 8.73332C0.524956 8.78304 0.414542 8.85592 0.321633 8.94776V8.94776C0.228356 9.03924 0.154344 9.14795 0.103842 9.26767C0.0533404 9.38738 0.0273437 9.51573 0.0273438 9.64536C0.0273437 9.77499 0.0533404 9.90334 0.103842 10.0231C0.154344 10.1428 0.228356 10.2515 0.321633 10.343L5.66763 15.6057C6.23158 16.16 6.99583 16.4713 7.79263 16.4713C8.58944 16.4713 9.35369 16.16 9.91763 15.6057L23.7356 2.00327C23.8288 1.91181 23.9026 1.80315 23.9531 1.68353C24.0035 1.56391 24.0294 1.43567 24.0294 1.30616C24.0294 1.17665 24.0035 1.04841 23.9531 0.928789C23.9026 0.809167 23.8288 0.700514 23.7356 0.609052C23.6427 0.517211 23.5323 0.444336 23.4107 0.394612C23.2891 0.344887 23.1588 0.31929 23.0271 0.31929C22.8955 0.31929 22.7651 0.344887 22.6435 0.394612C22.522 0.444336 22.4115 0.517211 22.3186 0.609052Z"
                          fill="#0C271B"
                        />
                      </svg>

                      {fe}
                    </li>
                  ))}
                </ul>
              </Col>
              <Col md={3} className="text-center">
                <div className="h-100 d-flex flex-column align-items-md-end align-items-start">
                  {plan.plan_title === "Premium" && (
                    <div className="cs-plan-badge mb-md-0 mb-3">
                      <svg
                        width="14"
                        height="14"
                        viewBox="0 0 14 14"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g clipPath="url(#clip0_511_3817)">
                          <path
                            d="M13.5455 3.35008L11.7891 0.977084C11.5731 0.675112 11.2883 0.428965 10.9582 0.259045C10.6281 0.0891242 10.2622 0.000323004 9.89094 1.02806e-06H4.11011C3.74065 -0.000345762 3.37638 0.0870478 3.04729 0.25499C2.7182 0.422931 2.4337 0.66662 2.21719 0.966001L0.429276 3.35592C0.138172 3.7664 -0.0121642 4.26013 0.000769877 4.76319C0.013704 5.26625 0.189212 5.7516 0.501026 6.14658L5.62969 13.3379C5.79313 13.5449 6.00145 13.712 6.23892 13.8267C6.47639 13.9414 6.73681 14.0007 7.00053 14C7.26877 13.9995 7.53331 13.9374 7.7738 13.8186C8.01428 13.6997 8.2243 13.5273 8.38769 13.3146L13.4755 6.20317C13.8013 5.80241 13.9849 5.30489 13.9976 4.78858C14.0103 4.27226 13.8513 3.76634 13.5455 3.35008V3.35008ZM10.8459 1.66308L12.6064 4.04192C12.6157 4.05475 12.618 4.06992 12.6274 4.08333H9.77369L8.94069 1.16667H9.89094C10.0782 1.16725 10.2625 1.21261 10.4287 1.29897C10.5948 1.38533 10.7378 1.51017 10.8459 1.66308V1.66308ZM7.00053 11.1522L5.42553 5.25H8.57553L7.00053 11.1522ZM5.44069 4.08333L6.27369 1.16667H7.72736L8.56036 4.08333H5.44069ZM3.16044 1.65492C3.26868 1.50406 3.41125 1.38111 3.57637 1.29622C3.74149 1.21132 3.92444 1.16692 4.11011 1.16667H5.06036L4.22736 4.08333H1.35269C1.36086 4.0705 1.36319 4.05475 1.37253 4.0425L3.16044 1.65492ZM1.42853 5.43958C1.38641 5.38009 1.35026 5.31659 1.32061 5.25H4.21919L5.96569 11.8008L1.42853 5.43958ZM8.03361 11.8067L9.78361 5.25H12.6921C12.6545 5.33707 12.607 5.41949 12.5504 5.49558L8.03361 11.8067Z"
                            fill="white"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_511_3817">
                            <rect width="14" height="14" fill="white" />
                          </clipPath>
                        </defs>
                      </svg>
                      <span className="ms-2">Include Free features</span>
                    </div>
                  )}
                  <button
                    onClick={() =>
                      choosePlanHandler(plan.plan_code)
                    }
                    className={`mt-auto btn btn-lg ${
                      (chosenPlan && chosenPlan === plan.plan_code) ||
                      (prevPlan && prevPlan === plan.plan_code)
                        ? "btn-light"
                        : "btn-dark"
                    }`}
                  >
                    {isNewUser &&
                    plan.recurrence === "ANNUALLY" &&
                    plan.plan_title === "Premium"
                      ? "Start 14 days trial"
                      : "Get Started"}
                  </button>
                </div>
              </Col>
            </Row>
          </div>
        ));
    else
      return plans.map((plan, index) => (
        <div
          className={`planCard my-3 ${
            (chosenPlan && chosenPlan === plan.plan_code) ||
            (prevPlan && prevPlan === plan.plan_code)
              ? "active"
              : ""
          }`}
          key={index}
        >
          <Row>
            <Col md={4} className="mb-md-0 mb-3 cl1">
              <h3>{plan.plan_title}</h3>
              <h1>
                {plan.amount / 100 > 0 ? (
                  <>
                    {plan.recurrence === "ANNUALLY" ? (
                      <>
                        {(plan.amount / 100 / 12).toLocaleString("en-US", {
                          style: "currency",
                          currency: plan.currency,
                          maximumFractionDigits: 0,
                          minimumFractionDigits: 0,
                        })}
                        <small> / Month </small>
                        <sup>
                          <span
                            style={{
                              color: "#808080",
                              textDecoration: "line-through",
                            }}
                          >
                            <span style={{ color: "#808080" }}>
                              {cdnPlans.find(
                                (item) =>
                                  item.recurrence === "MONTHLY" &&
                                  item.plan_title === "Premium"
                              )?.amount &&
                                "$" +
                                  (cdnPlans.find(
                                    (item) =>
                                      item.recurrence === "MONTHLY" &&
                                      item.plan_title === "Premium"
                                  )?.amount as number) /
                                    100}
                            </span>
                          </span>
                        </sup>
                      </>
                    ) : (
                      <>
                        {(plan.amount / 100).toLocaleString("en-US", {
                          style: "currency",
                          currency: plan.currency,
                          maximumFractionDigits: 0,
                        })}
                        <small> / Month </small>
                      </>
                    )}
                  </>
                ) : null}

                {/* {(plan.amount / 100).toLocaleString('en-US', {
              style: 'currency',
              currency: plan.currency,
              maximumFractionDigits: 0
            }) + " " + plan.currency} */}
              </h1>
              {plan.recurrence === "ANNUALLY" && plan.plan_title !== "Free" && (
                <a className="btn btn-dark rounded-pill mt-3">
                  Annual one-time charge of <b>${plan.amount / 100}</b>
                </a>
              )}
              {plan.recurrence === "MONTHLY" && plan.plan_title !== "Free" && (
                <a className="btn btn-dark rounded-pill mt-3">
                  Pay annually and save upt to <b>20%</b>
                </a>
              )}
            </Col>
            <Col md={5} className="mb-md-0 mb-3">
              <ul className="list-unstyled mb-0 p-0 h-100 d-flex flex-column featuresList">
                {plan.features.map((fe: string, index: number) => (
                  <li key={index}>
                    <svg
                      width="24"
                      height="17"
                      viewBox="0 0 24 17"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M22.3186 0.609052L8.49963 14.2145C8.40672 14.3063 8.29631 14.3792 8.17473 14.4289C8.05314 14.4786 7.92279 14.5042 7.79113 14.5042C7.65948 14.5042 7.52912 14.4786 7.40754 14.4289C7.28596 14.3792 7.17554 14.3063 7.08263 14.2145L1.73863 8.94776C1.64572 8.85592 1.53531 8.78304 1.41373 8.73332C1.29214 8.68359 1.16179 8.658 1.03013 8.658C0.898479 8.658 0.768121 8.68359 0.646539 8.73332C0.524956 8.78304 0.414542 8.85592 0.321633 8.94776V8.94776C0.228356 9.03924 0.154344 9.14795 0.103842 9.26767C0.0533404 9.38738 0.0273437 9.51573 0.0273438 9.64536C0.0273437 9.77499 0.0533404 9.90334 0.103842 10.0231C0.154344 10.1428 0.228356 10.2515 0.321633 10.343L5.66763 15.6057C6.23158 16.16 6.99583 16.4713 7.79263 16.4713C8.58944 16.4713 9.35369 16.16 9.91763 15.6057L23.7356 2.00327C23.8288 1.91181 23.9026 1.80315 23.9531 1.68353C24.0035 1.56391 24.0294 1.43567 24.0294 1.30616C24.0294 1.17665 24.0035 1.04841 23.9531 0.928789C23.9026 0.809167 23.8288 0.700514 23.7356 0.609052C23.6427 0.517211 23.5323 0.444336 23.4107 0.394612C23.2891 0.344887 23.1588 0.31929 23.0271 0.31929C22.8955 0.31929 22.7651 0.344887 22.6435 0.394612C22.522 0.444336 22.4115 0.517211 22.3186 0.609052Z"
                        fill="#0C271B"
                      />
                    </svg>

                    {fe}
                  </li>
                ))}
              </ul>
            </Col>
            <Col md={3} className="text-center">
              <div className="h-100 d-flex flex-column align-items-md-end align-items-start">
                {plan.plan_title === "Premium" && (
                  <div className="cs-plan-badge mb-md-0 mb-3">
                    <svg
                      width="14"
                      height="14"
                      viewBox="0 0 14 14"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g clipPath="url(#clip0_511_3817)">
                        <path
                          d="M13.5455 3.35008L11.7891 0.977084C11.5731 0.675112 11.2883 0.428965 10.9582 0.259045C10.6281 0.0891242 10.2622 0.000323004 9.89094 1.02806e-06H4.11011C3.74065 -0.000345762 3.37638 0.0870478 3.04729 0.25499C2.7182 0.422931 2.4337 0.66662 2.21719 0.966001L0.429276 3.35592C0.138172 3.7664 -0.0121642 4.26013 0.000769877 4.76319C0.013704 5.26625 0.189212 5.7516 0.501026 6.14658L5.62969 13.3379C5.79313 13.5449 6.00145 13.712 6.23892 13.8267C6.47639 13.9414 6.73681 14.0007 7.00053 14C7.26877 13.9995 7.53331 13.9374 7.7738 13.8186C8.01428 13.6997 8.2243 13.5273 8.38769 13.3146L13.4755 6.20317C13.8013 5.80241 13.9849 5.30489 13.9976 4.78858C14.0103 4.27226 13.8513 3.76634 13.5455 3.35008V3.35008ZM10.8459 1.66308L12.6064 4.04192C12.6157 4.05475 12.618 4.06992 12.6274 4.08333H9.77369L8.94069 1.16667H9.89094C10.0782 1.16725 10.2625 1.21261 10.4287 1.29897C10.5948 1.38533 10.7378 1.51017 10.8459 1.66308V1.66308ZM7.00053 11.1522L5.42553 5.25H8.57553L7.00053 11.1522ZM5.44069 4.08333L6.27369 1.16667H7.72736L8.56036 4.08333H5.44069ZM3.16044 1.65492C3.26868 1.50406 3.41125 1.38111 3.57637 1.29622C3.74149 1.21132 3.92444 1.16692 4.11011 1.16667H5.06036L4.22736 4.08333H1.35269C1.36086 4.0705 1.36319 4.05475 1.37253 4.0425L3.16044 1.65492ZM1.42853 5.43958C1.38641 5.38009 1.35026 5.31659 1.32061 5.25H4.21919L5.96569 11.8008L1.42853 5.43958ZM8.03361 11.8067L9.78361 5.25H12.6921C12.6545 5.33707 12.607 5.41949 12.5504 5.49558L8.03361 11.8067Z"
                          fill="white"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_511_3817">
                          <rect width="14" height="14" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>
                    <span className="ms-2">Include Free features</span>
                  </div>
                )}
                <button
                  onClick={() =>
                    choosePlanHandler(plan.plan_code)
                  }
                  className={`mt-auto btn btn-lg ${
                    (chosenPlan && chosenPlan === plan.plan_code) ||
                    (prevPlan && prevPlan === plan.plan_code)
                      ? "btn-light"
                      : "btn-dark"
                  }`}
                >
                  {isNewUser &&
                  plan.recurrence === "ANNUALLY" &&
                  plan.plan_title === "Premium"
                    ? "Start 14 days trial"
                    : "Get Started"}
                </button>
              </div>
            </Col>
          </Row>
        </div>
      ));
  };

  return (
    <div className="plans-page">
      <div className="d-flex flex-wrap align-items-center justify-content-between mb-3">
        <div className="cs-switch mb-md-0 mb-3">
          <Button
            variant={!isYearly ? "dark" : "link"}
            className="rounded-pill"
            onClick={() => setIsYearly(false)}
          >
            Monthly
          </Button>

          <Button
            variant={isYearly ? "dark" : "link"}
            className="rounded-pill"
            onClick={() => setIsYearly(true)}
          >
            Annually
          </Button>
        </div>
        {/* <div className='d-flex align-items-center'>
          Monthly
          <Form.Check
            type="switch"
            id="plans-switch"
            className='ms-2'
            defaultChecked={isYearly}
            label='Yearly'
            onChange={(e) => setIsYearly(e.target.checked ? true : false)}
          />
        </div> */}
        {((searchParams.has("plan") &&
          cdnPlans
            .map((plan) => plan.plan_code)
            .includes(searchParams.get("plan") as string)) ||
          (prevPlan &&
            cdnPlans
              .map((plan) => plan.plan_code)
              .includes(prevPlan as string))) && (
          <button
            onClick={() =>
              choosePlanHandler(searchParams.get("plan") || (prevPlan as string))
            }
            className="btn btn-outline-success btn-sm rounded-pill"
          >
            Continue with
            {planNameMapper((searchParams.get("plan") || prevPlan) as string)}
          </button>
        )}
      </div>
      {renderPlanSlides()}
    </div>
  );
};

export default PlansModalBody;


    // let credentials = localStorage.getItem("_cdn_credential");
    // let { is_new_user } = JSON.parse(credentials as string);
    // SetIsNewUser(is_new_user)