import axios from "axios";
import { storageAvailable } from "../providers/LocalStorageProvider";

let token: string | null | any;

const httpService = axios.create({
  baseURL: process.env.REACT_APP_CDN_BACK_END_DOMAIN
    ? process.env.REACT_APP_CDN_BACK_END_DOMAIN
    : "/cdn-service/api",
});

httpService.interceptors.response.use(undefined, (error) => {
  return Promise.reject(error);
});

const setToken = (authPayload: any) => {
  let auth = {
    username: "",
    password: "",
  };

  auth.username = authPayload.user_email;
  auth.password = authPayload.accessToken;

  token = auth;

  return;
};

const getToken = () => {
  let authPayload = null;

  if (storageAvailable('localStorage'))
    authPayload = localStorage.getItem("_cdn_service");


  let auth = {
    username: "",
    password: "",
  };

  if (authPayload) {
    let jsonAuthPayload = JSON.parse(authPayload);

    auth.username = jsonAuthPayload.user_email;
    auth.password = jsonAuthPayload.accessToken;
  } else {
    auth = token;
  }

  return auth;
};

const HttpService = {
  get: httpService.get,
  post: httpService.post,
  put: httpService.put,
  patch: httpService.patch,
  delete: httpService.delete,
  getToken,
  setToken,
};

export default HttpService;
