import { useEffect } from "react";
import { Link, useLocation } from "react-router-dom"
import HomeLayoutFooter from "./HomeLayoutFooter";
import HomeLayoutNavbar from "./HomeLayoutNavbar";

function HomeLayout({ children }: { children: React.ReactNode }) {
  const { pathname } = useLocation();

  useEffect(() => {
    window.document.documentElement.scrollTop = 0
  }, [])
  
  return (
    <main className="home_layout">
      {pathname !== '/contact' && (
        <Link to={'contact'} className="nav-side contact">
          <span >
            Contact
          </span>
        </Link>
      )}
      {pathname !== '/plans' && (
        <Link to={'plans'} className="nav-side plan" style={{ order: 2 }}>
          <span>
            Plan
          </span>
        </Link>
      )}
      <div className="main-content" style={{ order: pathname === '/' ? 3 : 0 }}>
        <HomeLayoutNavbar />
        {children}
        <HomeLayoutFooter />
      </div>
      {pathname !== '/' && (
        <Link to={''} className="nav-side home" style={{ order: 3 }}>
          <span>
            Home
          </span>
        </Link>
      )}
    </main >
  )
}

export default HomeLayout