import { useState } from "react"
import { Button, Form, Spinner } from "react-bootstrap"
import { useNavigate } from "react-router-dom"
import { useForm } from "react-hook-form";
import { joiResolver } from '@hookform/resolvers/joi';
import * as Joi from 'joi';
import useAuth from "../../hooks/useAuth";
import { useMutation, useQueryClient } from "react-query";
import HttpService from "../../services/http";
import { AxiosError } from "axios";
import { toast } from "react-toastify";
import BsInputPassword from "../../components/public/BsInputPassword";
import { Helmet } from "react-helmet-async";
import useAppLocalStorage from "../../hooks/useAppLocalStorage";


const schema = Joi.object({
  actual_password: Joi.string().required().label('Current password'),
  new_password: Joi.string().required().label('New password'),
  new_confirm_password: Joi.any().valid(Joi.ref("new_password")).required().messages({ "any.only": "Those passwords didn't match. Try again." }),
});

const LevelUpPassword = () => {
  const localStorage = useAppLocalStorage()
  const { customer_identification } = useAuth();
  const auth = useAuth();
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const [apiErrors, setApiErrors] = useState<any>({});

  const { register, handleSubmit, formState: { errors }, reset, setError } = useForm({
    resolver: joiResolver(schema)
  });

  const LevelUpPassword = {
    title: "Change your password to a stronger one.",
    resetBtn: "Chage password",
    brand: {
      title: "SUM",
      description: "Super user manager",
    },
  }

  const PasswordCheckMutation = useMutation<any, AxiosError<any, any>, { password: string, level?: string | null }>(
    (data) => HttpService.post('/auth/check-password', data),
    {
      onError: (err) => {
        toast.error(err.response?.data.message || err.message)

        setApiErrors({ ...apiErrors, new_password: err.response?.data?.message })
        setError('new_password', err.response?.data.message)
      },
    }
  )

  const UpdatePasswordMutation = useMutation<any, AxiosError<any, any>, any>((data) => HttpService.patch(`/${customer_identification}/profile/password`, { actual_password: data.actual_password, new_password: data.new_password }, {
    auth: HttpService.getToken()
  }), {
    onSuccess: () => {
      toast.success('Password updated');
      reset();

      logoutDirectlyToLogin()
    },
    onError: (err) => {
      toast.error(err.response?.data.message || err.message)

      if (err.response?.data?.message === "Credential error.") {
        setApiErrors({ ...apiErrors, actual_password: err.response?.data?.message })
      } else {
        setApiErrors({ ...apiErrors, new_password: err.response?.data?.message })
      }
    },
    onMutate: () => {
      setApiErrors({})
    }
  })

  const onSubmit = (values: any) => {
    PasswordCheckMutation.mutate({
      password: values.new_password,
      level: localStorage.getItem('_cdn_password_level')
    }, {
      onSuccess: () => {
        UpdatePasswordMutation.mutate(values);
      },
    })
  }


  const logoutDirectlyToLogin = () => {
    localStorage.removeItem("_cdn_service");
    auth.logout();
    queryClient.clear();
    navigate('/login');
  }

  const formChangeHandler = () => {
    setApiErrors({});
  }

  return (
    <>
      <Helmet>
        <title>1CDN - Level up password</title>
        <meta name="description" content="1CDN - Level up password" />
        <link rel="canonical" href={`${process.env.REACT_APP_PAYMENT_SERVICE_REDIRECT_PAGE}/level-up-password`} />
      </Helmet>

      <div className="d-flex justify-content-between align-items-center mb-4">
        <h1 className="fs-4 fw-bold mb-0">{LevelUpPassword.title}</h1>
      </div>

      <Form onSubmit={handleSubmit(onSubmit)} onChange={formChangeHandler}>
        <BsInputPassword label="Current password" isInvalid={!!errors.actual_password || !!apiErrors.actual_password} errorMessage={errors.actual_password?.message || apiErrors.actual_password} className={"mb-3"} rhfControls={register("actual_password")} />

        <BsInputPassword label="New password" isInvalid={!!errors.new_password || !!apiErrors.new_password} errorMessage={errors.new_password?.message || apiErrors.new_password} className={"mb-3"} rhfControls={register("new_password")} />

        <BsInputPassword label="Re-enter Password" isInvalid={!!errors.new_confirm_password} errorMessage={errors.new_confirm_password?.message} className={"mb-3"} rhfControls={register("new_confirm_password")} />

        <Button variant="primary" type="submit" className="w-100 rounded-pill auth-btn" size="lg" disabled={UpdatePasswordMutation.isLoading}>
          {UpdatePasswordMutation.isLoading ? <Spinner animation="grow" /> : LevelUpPassword.resetBtn}
        </Button>
      </Form>
    </>
  )
}

export default LevelUpPassword
