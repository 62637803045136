import React from "react";

export const contents = {
  Navbar: {
    navbarLinks: ["Features", "Plans", "About Us", "Contact"],
    navbarBrand: {
      title: "UMD",
      description: "User Management Dispatcher",
    },
    navbarAuth: {
      loginBtn: "Login",
      registerBtn: "Create Account",
      dashboard: "Dashboard"
    },
  },
  HomePage: {
    Section1: {
      title: "Our service is your best",
      titleColored: "Solution",
    },
    Section2: {
      title: "Save time profitably; Spend it",
      titleColored: "productively!",
      items: {
        secure: {
          title: "Secure",
          subTitle: "All of your data is in private",
        },
        access: {
          title: "Access",
          subTitle: "Access up to +1000 user",
        },
        flexible: {
          title: "Flexible",
          subTitle: "Your hand is open for change",
        },
      },
    },
    Section3: {
      titleColored: "Authentication",
      title: "has never been easier than today.",
      description:
        "By using UMD you can easily create authentication for your website by full of customization.",
      button: "Learn more",
      items: {
        support: {
          title: "Expert support",
          description:
            "We are here to provide you with the best services with an expert team, so sit back and enjoy! ",
        },
        email: {
          title: "Email confirmation",
          description:
            "It is important for you to ensure that your users have entered valid email addresses, so you can communicate with them.",
        },
        edit: {
          title: "Edit profile",
          description:
            "You can provide your users with UMD services so that they can update their information whenever needed.",
        },
        signup: {
          title: "Sign up form",
          description:
            "Create your sign-up form with any information you want.",
        },
      },
    },
    Section4: {
      website: "Website",
      confirmValidation: "confirmation & validation",
      newUser: "New Users",
    },
    Section5: {
      title: "Our",
      titleColored: "Plans",
      description:
        "Services that fit every business use case. choose your suitable plan.",
      button: "Register now",
      free: {
        title: "Free",
        plans: [
          "100 users/organization",
          "Sign up form",
          "Log in box",
          "Edit profile",
          "Email confirmation & validation",
          "Flexible user data",
          "Flexible user preferences",
          "Custom user avatar hosting",
          "Segmentation",
          "1000 users/organization",
          "User migration",
          "Expert support",
          "Custom email template",
          "Passwordless connections",
        ],
        button: "Get Started",
      },
      premium: {
        title: "Premium",
        plans: [
          "100 users/organization",
          "Sign up form",
          "Log in box",
          "Edit profile",
          "Email confirmation & validation",
          "Flexible user data",
          "Flexible user preferences",
          "Custom user avatar hosting",
          "Segmentation",
          "1000 users/organization",
          "User migration",
          "Expert support",
          "Custom email template",
          "Passwordless connections",
        ],
        button: "Coming soon",
      },
    },
    Section6: {
      title: "What our",
      titleColored: "Clients say”",
      says: {
        item_1: {
          text: "“It was a relief, no time wasted on forms, no concern, no trouble! and tada! there were my forms ready to use.”",
          name: "Mary Brown",
          position: "senior developer",
        },
        item_2: {
          text: "“If you have no idea how you should start for auth forms it can help you a lot with creating forms and managing users, so you need nothing to do.”",
          name: "James smith",
          position: "senior developer",
        },
        item_3: {
          text: "“So easy, so simple and friendly, just like what i was looking for.”",
          name: "tom Johnsonmes",
          position: "senior developer",
        },
        item_4: {
          text: "“If you are one of those people who like to work less and gain more, just like me, this is a great choice for you.”",
          name: "Jennifer Brown",
          position: "senior developer",
        },
      },
    },
    Section7: {
      title: "About",
      titleColored: "Us",
      description:
        "We are committed to providing a secure platform for you to manage your users and give you a free mind to focus on everything that makes you great. Our priority is to leave your hands free to get to know your users as you would like and allow them to easily manage their information on your website.",
      questions: {
        q1: {
          title: "How to register?",
          answer: "Registering at UMD is free and so easy, click on subscribe, and after a blink, you are one of us!",
        },
        q2: {
          title: "Why should we use UMD? ",
          answer: "",
        },
        q3: {
          title: "How UMD works?",
          answer: "",
        },
      },
    },
    Section8: {
      title:"Contact",
      titleColored: "Us"
    }
  },
  Login: {
    title: "Login",
    registerNote: "New to UMD?",
    registerBtn: "Sign up now!",
    forgotPassWordBtn: "Forgot password?",
    loginBtn: "Log in",
    brand: {
      title: "UMD",
      description: "user management dispatcher"
    }
  },
  Register: {
    title: "Sign up",
    loginNote: "Already have an account?",
    loginBtn: "Log in",
    registerBtn: "Sign up",
    brand: {
      title: "UMD",
      description: "user management dispatcher"
    }
  },
  ForgotPassword: {
    title: "Forgot password",
    loginNote: "Back to",
    loginBtn: "Log in",
    resetBtn: "Send link",
    brand: {
      title: "UMD",
      description: "user management dispatcher"
    }
  },
  ResetPassword: {
    title: "Reset password",
    loginNote: "Back to",
    loginBtn: "Log in",
    resetBtn: "Reset",
    brand: {
      title: "UMD",
      description: "user management dispatcher"
    }
  },
  Footer: {
    col1: {
      title: "UMD",
      description: "User Management Dispatcher",
    },
    col2: ["Features", "Plans", "About Us", "Contact"],
    col3: {
      contact: "umd@info.com",
    },
  },
  ExtraFooter: "Made with love in Switzerland by passionate teams",
};

export const ContentContext = React.createContext(contents);
