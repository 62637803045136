import { Col, Container, Row } from "react-bootstrap"
import HomeLayoutNavbar from "../home/HomeLayoutNavbar"

const AuthLayout = ({ children }: { children: React.ReactNode }) => {
  return (
    <>
      <HomeLayoutNavbar />
      <div className="auth_layout">
        <Container fluid={'lg'} className="h-100">
          <Row>
            <Col lg={{ offset: 6, span: 6 }} md={{ span: 10, offset: 1 }}>
              <div className="form_layout">
                {children}
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  )
}

export default AuthLayout