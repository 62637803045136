import React, { useContext } from 'react'
import { Accordion, AccordionContext, Col, Container, Row, useAccordionButton } from 'react-bootstrap'

function ContextAwareToggle({ children, eventKey, callback }: { children: any, eventKey: any, callback: any }) {
  const { activeEventKey } = useContext(AccordionContext);

  const decoratedOnClick = useAccordionButton(
    eventKey,
    () => callback && callback(eventKey),
  );

  const isCurrentEventKey = activeEventKey === eventKey;

  return (
    <>
      {isCurrentEventKey ? <div style={{ cursor: 'pointer' }} className="w-100 d-flex align-items-center justify-content-between" onClick={decoratedOnClick}>
        {children}
        <svg width="30" height="31" viewBox="0 0 30 31" fill="none" xmlns="http://www.w3.org/2000/svg">
          <circle cx="15" cy="15.0371" r="14.5" stroke="#777C79" />
          <line x1="21" y1="16.0371" x2="9" y2="16.0371" stroke="#57CC99" strokeWidth="2" strokeLinecap="round" />
        </svg>
      </div> : <div style={{ cursor: 'pointer' }} className="w-100 d-flex align-items-center justify-content-between" onClick={decoratedOnClick}>
        {children}
        <svg width="30" height="31" viewBox="0 0 30 31" fill="none" xmlns="http://www.w3.org/2000/svg">
          <circle cx="15" cy="15.0371" r="14.5" stroke="#777C79" />
          <line x1="21" y1="15.0371" x2="9" y2="15.0371" stroke="#57CC99" strokeWidth="2" strokeLinecap="round" />
          <line x1="15" y1="21.0371" x2="15" y2="9.03711" stroke="#57CC99" strokeWidth="2" strokeLinecap="round" />
        </svg>
      </div>}
    </>
  )
}

const questions = [
  {
    question: "How to register?",
    answer: "We made it so easy for you! Use an email address to register and then you can save your files in 1CDN and share them anytime and anywhere. We take care of your files."
  },
  {
    question: "Why should we use 1CDN?",
    answer: "It is essential to provide users with a fast-loading website experience in today's world. If your users come from all over the world, it is very costly to have several servers in different locations, which is why we recommend using the 1CDN to speed up your website and lower your expenses."
  },
  {
    question: "How 1CDN works?",
    answer: "After registration, you just have to upload your files and you can get their information, at any time, including their url on the 1CDN servers. Use this path anywhere you need to."
  },
]


const Section6 = () => {
  return (
    <section className='section6 cs-p'>
      <Container fluid='lg'>
        <Row className='justify-content-center my-sm-5 my-2'>
          <Col lg={6} md={9} xs={12}>
            <h5 className='coloredTitle fs-1'>
              <span>
                Any Questions?
              </span>
              <br />
              look here
            </h5>
            <Accordion>
              {questions.map((question: { question: string, answer: string }, index: any) => (
                <div className="cs-accordion-item" key={index}>
                  <div className="d-flex align-items-center justify-content-between">
                    <ContextAwareToggle eventKey={index} callback={undefined}>
                      <h5>
                        <span className='h1 me-3'>0{index + 1}</span>
                        {question.question}
                      </h5>
                    </ContextAwareToggle>
                  </div>
                  <Accordion.Collapse eventKey={index}>
                    <div className='py-4'>
                      <p className="mb-0">
                        {question.answer}
                      </p>
                    </div>
                  </Accordion.Collapse>
                </div>
              ))}
            </Accordion>
          </Col>
        </Row>
      </Container>
    </section>
  )
}

export default Section6